import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBHGcZd8_XtXdOMjFspj3ZXoGjUgRsO4rA",
  authDomain: "daxtop-portal.firebaseapp.com",
  projectId: "daxtop-portal",
  storageBucket: "daxtop-portal.appspot.com",
  messagingSenderId: "1051383768274",
  appId: "1:1051383768274:web:45600f1d67b36f24bd390b",
  measurementId: "G-DP9CLMY6YK"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { db, auth, storage }; // Export storage to use in other files
