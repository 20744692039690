import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ItemAdd from './components/ItemAdd';
import Inventory from './components/Inventory';
import TakeOut from './components/TakeOut';
import Reports from './components/Reports';
import NavBar from './components/NavBar';
import Profile from './components/Profile';
import AddStore from './components/AddStore';
import Notifications from './components/Notifications';
import LandingPage from './components/LandingPage';
import Finances from './components/Finances';
import DaxAI from './components/DaxAI';
import MarketResearch from './components/MarketResearch';


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        fetchBrandName(user.uid);
      } else {
        setBrandName('');
        setNotificationCount(0);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && brandName) {
      const db = getFirestore();
      const notificationsRef = collection(db, `stores/${brandName}/notifications`);
      const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
        const activeNotifications = snapshot.docs.filter(doc => {
          const data = doc.data();
          return data.statusPro !== 'Dismissed' && 
            (!data.postponedUntil || data.postponedUntil.toDate() <= new Date()) &&
            !data.seen;
        });
        setNotificationCount(activeNotifications.length);
      });
  
      return () => unsubscribe();
    }
  }, [user, brandName]);
  
  const fetchBrandName = async (userId) => {
    const db = getFirestore();
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const storeData = snapshot.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);
      }
    });
  
    return () => unsubscribe();
  };

  const AppContent = () => {
    const location = useLocation();
    const showMainNavbar = !location.pathname.includes('/inventory/finances/');

    return (
      <>
        {user && showMainNavbar && <NavBar notificationCount={notificationCount} />}
        <Routes>
          <Route path="/" element={user ? <Navigate to="/inventory/dashboard" /> : <LandingPage />} />
          <Route path="/inventory/*" element={<InventoryRoutes user={user} />} />
          <Route path="/login" element={user ? <Navigate to="/inventory/dashboard" /> : <Login />} />
          <Route path="/register/Q0rt444asdf15k357" element={user ? <Navigate to="/inventory/dashboard" /> : <Register />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );
  };

  const InventoryRoutes = ({ user }) => (
    <Routes>
      <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />
      <Route path="/item-add" element={user ? <ItemAdd /> : <Navigate to="/" />} />
      <Route path="/item-storage" element={user ? <Inventory /> : <Navigate to="/" />} />
      <Route path="/takeout" element={user ? <TakeOut /> : <Navigate to="/" />} />
      <Route path="/reports" element={user ? <Reports /> : <Navigate to="/" />} />
      <Route path="/profile" element={user ? <Profile /> : <Navigate to="/" />} />
      <Route path="/add-store" element={user ? <AddStore /> : <Navigate to="/" />} />
      <Route path="/notifications" element={user ? <Notifications /> : <Navigate to="/" />} />
      <Route path="/finances/*" element={user ? <Finances /> : <Navigate to="/" />} />
      <Route path="/dax" element={user ? <DaxAI /> : <Navigate to="/" />} />
      <Route path="/dax/new" element={user ? <DaxAI /> : <Navigate to="/" />} />
      <Route path="/dax/:chatId" element={user ? <DaxAI /> : <Navigate to="/" />} />
    </Routes>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {window.location.hostname === 'market.daxtop.com' ? (
          <MarketResearch />
        ) : (
          <AppContent />
        )}
      </div>
    </Router>
  );
}

export default App;