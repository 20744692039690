import React from 'react';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({ message, onConfirm, onCancel, confirmText, cancelText }) => {
  return (
    <div className="confirmation-overlay-confirm-page">
      <div className="confirmation-dialog-confirm-page">
        <div className="confirmation-content-confirm-page">
          <p className="confirmation-message-confirm-page">{message}</p>
          <div className="confirmation-buttons-confirm-page">
            <button onClick={onCancel} className="cancel-btn-confirm">
              {cancelText}
            </button>
            <button onClick={onConfirm} className="confirm-btn-confirm">
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;