import React from 'react';
import { X, ChevronRight, Search } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import './MobileMenuNavBar.css';

const MobileMenuNavBar = ({ isOpen, onClose, notificationCount }) => {
    const location = useLocation();

    const menuItems = [
        { title: 'Dashboard', path: '/inventory/dashboard' },
        { title: 'Inventory', path: '/inventory/item-storage' },
        { title: 'Add', path: '/inventory/item-add' },
        { title: 'Take Out', path: '/inventory/takeout' },
        { title: 'Reports', path: '/inventory/reports' },
        { title: 'Profile', path: '/inventory/profile' },
        { title: 'Notifications', path: '/inventory/notifications', count: notificationCount },
        { title: 'Finances', path: '/inventory/finances/accounting' }
    ];

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    if (!isOpen) return null;

    return (
        <div className="mobile-menu-overlay-n">
            <div className={`mobile-menu-container-n ${isOpen ? 'open' : ''}`}>
                <div className="mobile-menu-header-n">
                    <h2></h2>
                    <button className="mobile-menu-close-n" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>
                <div className="mobile-menu-search-container-n">
                    <div className="mobile-menu-search-n">
                        <Search size={20} />
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
                <nav className="mobile-nav-n">
                    <ul>
                        {menuItems.map((item) => (
                            <li key={item.path}>
                                <Link
                                    to={item.path}
                                    className={`mobile-menu-item-n ${isActive(item.path)} ${item.title === 'Finances' ? 'finances-item' : ''}`}
                                    onClick={onClose}
                                >
                                    <span>{item.title}</span>
                                    {item.count !== undefined && (
                                        <span className="notification-count">
                                            {item.count > 99 ? '99+' : item.count}
                                        </span>
                                    )}
                                    <ChevronRight size={20} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default MobileMenuNavBar;