import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './AddStore.css';

const AddStore = () => {
  const [storeInfo, setStoreInfo] = useState({
    country: '',
    state: '',
    city: '',
    address: '',
    size: '',
    employees: '',
    phone: '',
    contactEmail: ''
  });
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');


  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setStoreInfo({ ...storeInfo, [e.target.name]: e.target.value });
  };

  const validateStoreInfo = () => {
    const requiredFields = [ 'country', 'state', 'city', 'address', 'size', 'employees', 'phone'];
    return requiredFields.every(field => storeInfo[field].trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSaveStatus('saving');

    if (!validateStoreInfo()) {
      setError('Please fill in all required fields');
      setSaveStatus('error');
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        const storesQuery = query(collection(db, 'stores'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(storesQuery);

        if (!querySnapshot.empty) {
          const storeDoc = querySnapshot.docs[0];
          const storeData = storeDoc.data();
          setModifiedBrandName(storeData.modifiedBrandName);

          if (storeData.status === 'Store') {
            await updateDoc(storeDoc.ref, {
              status: 'Store Network'
            });

            await setDoc(doc(db, `stores/${storeData.modifiedBrandName}/networkStores`, storeData.address), {
              ...storeData,
              storeName: 'Main Store'
            });

            await setDoc(doc(db, `stores/${storeData.modifiedBrandName}/networkStores`, storeInfo.address), {
              ...storeInfo,
              storeName: storeInfo.storeName + ' Branch'
            });
          } else if (storeData.status === 'Store Network') {
            await setDoc(doc(db, `stores/${storeData.modifiedBrandName}/networkStores`, storeInfo.address), {
              ...storeInfo,
              storeName: storeInfo.storeName + ' Branch'
            });
          }

          setSaveStatus('success');
          setTimeout(() => {
            navigate('/inventory/profile');
          }, 2000);
        } else {
          setError('No store information found');
          setSaveStatus('error');
        }
      } else {
        setError('No authenticated user found');
        setSaveStatus('error');
      }
    } catch (err) {
      setError(`Error adding store: ${err.message}`);
      setSaveStatus('error');
      console.error(err);
    }
  };


  return (
    <div className="addstore-container">
      <h2 className="addstore-title">Add New Store</h2>
      {saveStatus && (
        <div className={`save-status ${saveStatus}`}>
          {saveStatus === 'saving' && 'Saving changes...'}
          {saveStatus === 'success' && 'Store added successfully!'}
          {saveStatus === 'error' && 'Error adding store. Please try again.'}
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="addstore-form">
      <div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="text"
    id="country"
    name="country"
    value={storeInfo.country}
    onChange={handleChange}
    placeholder="Country"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="text"
    id="state"
    name="state"
    value={storeInfo.state}
    onChange={handleChange}
    placeholder="State"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="text"
    id="city"
    name="city"
    value={storeInfo.city}
    onChange={handleChange}
    placeholder="City"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="text"
    id="address"
    name="address"
    value={storeInfo.address}
    onChange={handleChange}
    placeholder="Address"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="number"
    id="size"
    name="size"
    value={storeInfo.size}
    onChange={handleChange}
    placeholder="Size (sq m)"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="number"
    id="employees"
    name="employees"
    value={storeInfo.employees}
    onChange={handleChange}
    placeholder="Number of Employees"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="tel"
    id="phone"
    name="phone"
    value={storeInfo.phone}
    onChange={handleChange}
    placeholder="Contact Phone"
    required
  />
</div>
<div className="addstore-form-group">
  <input
    className="addstore-form-group-input"
    type="email"
    id="contactEmail"
    name="contactEmail"
    value={storeInfo.contactEmail}
    onChange={handleChange}
    placeholder="Contact Email (optional)"
  />
</div>
        <button type="submit" className="addstore-submit-btn" disabled={saveStatus === 'saving'}>
          {saveStatus === 'saving' ? 'Adding Store...' : 'Add Store'}
        </button>
      </form>
    </div>
  );
};

export default AddStore;



