import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, updateDoc, deleteDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { SimpleLoad } from './LoadingPages/LoadingAnimation';
import ConfirmationDialog from './ConfirmationDialog';
import './Profile.css';

const Profile = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingStore, setEditingStore] = useState(null);
  const [expandedStore, setExpandedStore] = useState(null);
  const [hoveredStore, setHoveredStore] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [storeToDelete, setStoreToDelete] = useState(null);


  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  const fetchStoreInfo = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const storesQuery = query(collection(db, 'stores'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(storesQuery);
        
        if (!querySnapshot.empty) {
          const storeDoc = querySnapshot.docs[0];
          const storeData = { id: storeDoc.id, ...storeDoc.data() };
          setStoreInfo(storeData);
          setModifiedBrandName(storeData.modifiedBrandName);
          
          if (storeData.status === 'Store Network') {
            const networkStoresRef = collection(db, `stores/${storeData.modifiedBrandName}/networkStores`);
            const storesSnapshot = await getDocs(networkStoresRef);
            setStores(storesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          } else {
            setStores([storeData]);
          }
        } else {
          setError('No store information found');
        }
      }
    } catch (err) {
      setError('Error fetching store information');
      console.error('Error fetching store information:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteStore = async (storeId) => {
    setStoreToDelete(storeId);
    setShowConfirmation(true);
  };

  const confirmDeleteStore = async () => {
    setShowConfirmation(false);
    try {
      await deleteDoc(doc(db, `stores/${modifiedBrandName}/networkStores`, storeToDelete));
      const updatedStores = stores.filter(store => store.id !== storeToDelete);
      setStores(updatedStores);
  
      if (updatedStores.length === 1) {
        const lastStore = updatedStores[0];
        await updateDoc(doc(db, 'stores', storeInfo.id), {
          status: 'Store',
          address: lastStore.address,
          country: lastStore.country,
          state: lastStore.state,
          city: lastStore.city,
          size: lastStore.size,
          employees: lastStore.employees,
          phone: lastStore.phone,
          contactEmail: lastStore.contactEmail
        });
        setStoreInfo({ ...storeInfo, ...lastStore, status: 'Store' });
      }
    } catch (err) {
      setError('Error deleting store');
      console.error('Error deleting store:', err);
    } finally {
      setStoreToDelete(null);
    }
  };
  
  const cancelDeleteStore = () => {
    setShowConfirmation(false);
    setStoreToDelete(null);
  };



  const handleEditStore = (store) => {
    setEditingStore({ ...store });
  };

  const handleSaveChanges = async () => {
    setSaveStatus('saving');
    try {
      let storeRef;
      if (storeInfo.status === 'Store') {
        storeRef = doc(db, 'stores', storeInfo.id);
      } else {
        storeRef = doc(db, `stores/${modifiedBrandName}/networkStores`, editingStore.id);
      }

      await updateDoc(storeRef, {
        address: editingStore.address,
        country: editingStore.country,
        state: editingStore.state,
        city: editingStore.city,
        size: editingStore.size,
        employees: editingStore.employees,
        phone: editingStore.phone,
        contactEmail: editingStore.contactEmail
      });
      
      if (storeInfo.status === 'Store') {
        setStoreInfo({ ...storeInfo, ...editingStore });
        setStores([{ ...storeInfo, ...editingStore }]);
      } else {
        setStores(stores.map(store => store.id === editingStore.id ? editingStore : store));
      }
      
      setSaveStatus('success');
      setTimeout(() => setSaveStatus(null), 3000);
    } catch (err) {
      console.error('Error updating store:', err);
      setSaveStatus('error');
      setError(`Error saving changes: ${err.message}`);
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setEditingStore(null);
    }
  };


  const handleCancelEdit = () => {
    setEditingStore(null);
  };

  const handleInputChange = (e) => {
    setEditingStore({ ...editingStore, [e.target.name]: e.target.value });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (err) {
      setError('Error signing out');
      console.error('Error signing out:', err);
    }
  };

  if (loading) {
    return <SimpleLoad onLoadComplete={() => setLoading(false)} />;
  }
  if (error) return <div className="error-message">{error}</div>;
  if (!storeInfo) return <div>No store information found</div>;
  
  
  return (
    <div className='profile-container'>
      <h2 className='profile-title'>Profile</h2>
      <h3 className='store-info-header'>Store Information</h3>
      <p>Status: {storeInfo.status}</p>

      {saveStatus && (
        <div className={`save-status ${saveStatus}`}>
          {saveStatus === 'saving' && 'Saving changes...'}
          {saveStatus === 'success' && 'Changes saved successfully!'}
          {saveStatus === 'error' && 'Error saving changes. Please try again.'}
        </div>
      )}

      <div className='stores-list'>
        {stores.map(store => (
          <div 
            key={store.id} 
            className={`store-item ${expandedStore === store.id ? 'expanded' : ''}`}
            onMouseEnter={() => setHoveredStore(store.id)}
            onMouseLeave={() => setHoveredStore(null)}
          >
            <h4>{store.address}</h4>
            {!editingStore && (
              <button 
                className={`more-button ${hoveredStore === store.id ? 'visible' : ''}`}
                onClick={() => setExpandedStore(expandedStore === store.id ? null : store.id)}
              >
                {expandedStore === store.id ? 'Less' : 'More'}
              </button>
            )}
            {expandedStore === store.id && (
              <div className='expanded-store'>
                {editingStore && editingStore.id === store.id ? (
                  <div className='edit-form'>
                    <input className='edit-form-input' name="address" value={editingStore.address} onChange={handleInputChange} placeholder="Address" />
                    <input className='edit-form-input' name="country" value={editingStore.country} onChange={handleInputChange} placeholder="Country" />
                    <input className='edit-form-input' name="state" value={editingStore.state} onChange={handleInputChange} placeholder="State" />
                    <input className='edit-form-input' name="city" value={editingStore.city} onChange={handleInputChange} placeholder="City" />
                    <input className='edit-form-input' name="size" value={editingStore.size} onChange={handleInputChange} placeholder="Size (sq m)" />
                    <input className='edit-form-input' name="employees" value={editingStore.employees} onChange={handleInputChange} placeholder="Employees" />
                    <input className='edit-form-input' name="phone" value={editingStore.phone} onChange={handleInputChange} placeholder="Phone" />
                    <input className='edit-form-input' name="contactEmail" value={editingStore.contactEmail} onChange={handleInputChange} placeholder="Email" />
                    <div className="button-group">
                      <button onClick={handleSaveChanges} className='buttons'>Save Changes</button>
                      <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>Address: {store.address}</p>
                    <p>Country: {store.country}</p>
                    <p>State: {store.state}</p>
                    <p>City: {store.city}</p>
                    <p>Size: {store.size} sq m</p>
                    <p>Employees: {store.employees}</p>
                    <p>Phone: {store.phone}</p>
                    <p>Email: {store.contactEmail || 'N/A'}</p>
                    <div className="button-group">
                      <button onClick={() => handleEditStore(store)} className='buttons'>Edit</button>
                      {storeInfo.status === 'Store Network' && stores.length > 1 && (
                        <button onClick={() => handleDeleteStore(store.id)} className="delete-button">Delete</button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {(storeInfo.status === 'Store Network' || stores.length === 1) && (
        <Link to="/inventory/add-store">
          <button className='buttons-add-item'>Add Store</button>
        </Link>
      )}

      <button onClick={handleLogout} className='logout-button'>Logout</button>

      {showConfirmation && (
     
        <ConfirmationDialog
    message="Are you sure you want to delete?"
    onConfirm={confirmDeleteStore}
    onCancel={cancelDeleteStore}
    confirmText="Confirm"
    cancelText="Cancel"
  />
      )}
    </div>
  );
};

export default Profile;
