import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { Home, Box, Edit, ShoppingCart, FileText, User, Bell, Menu, X, Search, ChevronRight, Bot } from 'lucide-react';
import './NavBar.css';
import { translations } from './Data/data';
import logoInventory from './Images/Inventory-logo.webp';
import MobileMenuNavBar from './MobileMenuNavBar.js';

const NavBar = () => {
  const auth = getAuth();
  const db = getFirestore();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [language, setLanguage] = useState('en');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const isActive = (path) => {
    if (path === '/inventory/dax') {
      return location.pathname.startsWith('/inventory/dax') ? 'active' : '';
    }
    return location.pathname.startsWith(path) ? 'active' : '';
  };


  const handleDaxAIClick = () => {
    navigate('/inventory/dax/new');
  };

  const handleFinancesClick = () => {
    navigate('/inventory/finances/accounting');
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchBrandName(user.uid);
    }
  }, []);

  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
      if (window.innerWidth > 1200) {
        setIsMobileMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchBrandName = async (userId) => {
    const storesRef = collection(db, 'stores');
    const q = query(storesRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const storeData = snapshot.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);
      }
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (modifiedBrandName) {
      const notificationsRef = collection(db, `stores/${modifiedBrandName}/notifications`);
      const q = query(notificationsRef, where('statusPro', 'in', ['', 'Postponed']));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const now = new Date();
        const activeNotifications = snapshot.docs.filter(doc => {
          const data = doc.data();
          return (data.statusPro !== 'Postponed' || 
            (data.postponedUntil && data.postponedUntil.toDate() <= now)) &&
            !data.seen;
        });
        setNotificationCount(activeNotifications.length);
      });

      return () => unsubscribe();
    }
  }, [brandName]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    // Implement search functionality here
  };

  const navLinks = [
    { path: '/inventory/dashboard', icon: Home, label: 'Dashboard' },
    { path: '/inventory/item-storage', icon: Box, label: 'Inventory' },
    { path: '/inventory/item-add', icon: Edit, label: 'Add' },
    { path: '/inventory/takeout', icon: ShoppingCart, label: 'Take Out' },
    { path: '/inventory/reports', icon: FileText, label: 'Reports' },
    { path: '/inventory/profile', icon: User, label: 'Profile' },
    { path: '/inventory/notifications', icon: Bell, count: notificationCount },
    
  ];

  const showNavBar = !location.pathname.includes('/inventory/finances/');
  const showDaxButton = !location.pathname.startsWith('/inventory/dax');

  return (
    showNavBar && (
      <nav className={`navbar ${isMobileView ? 'mobile-view' : ''}`}>
        <div className="navbar-container">
          <div className="navbar-left">
            <div className="navbar-logo">
              <Link to="/inventory/dashboard">
                <img src={logoInventory} alt="Logo" />
              </Link>
            </div>
            {!isMobileView && (
              <ul className="navbar-links">
                {navLinks.map((link) => (
                  <li key={link.path}>
                    <Link to={link.path === '/inventory/dax' ? '/inventory/dax/new' : link.path} className={isActive(link.path)}>
                      <link.icon size={18} />
                      {link.label}
                      {link.count > 0 && (
                        <span className="notification-count">
                          {link.count > 99 ? '99+' : link.count}
                        </span>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="navbar-right">
            {!isMobileView && (
              <button className="finances-btn" onClick={handleFinancesClick}>Finances</button>
            )}
            {isMobileView && (
              <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                <Menu size={24} />
              </button>
            )}
          </div>
        </div>

        <MobileMenuNavBar 
          isOpen={isMobileMenuOpen}
          onClose={toggleMobileMenu}
          translate={translate}
          notificationCount={notificationCount}
        />

        {showDaxButton && (
          <div className="dax-ai-button" onClick={handleDaxAIClick}>
            <p>DAX AI</p>
            <Bot size={22} />
          </div>
        )}
      </nav>
    )
  );
};

export default NavBar;