import React from 'react';
import ReactMarkdown from 'react-markdown';

const MessageFormatter = ({ content, isUser }) => {
  return (
    <div className={`message ${isUser ? 'user' : ''}`}>
      <div className="message-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default MessageFormatter;