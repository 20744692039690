import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, AlertTriangle, Mail } from 'lucide-react';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logoFinances from './Images/Finances-logo.webp';
import './Finances.css';
import MobileMenuFinances from './MobileMenuFinances.js';
import Accounting from './Accounting.js';

const FinancesOverlay = () => {
  return (
    <div className="finances-overlay">
      <div className="finances-overlay-content">
        <AlertTriangle size={48} className="alert-icon" />
        <h2>Under Development</h2>
      
        <p>
        For urgent matters, please contact our Support Team.
        </p>
        <a href="mailto:support@daxtop.com" className="support-email">
          <Mail size={18} />
          support@daxtop.com
        </a>
        <p className="thank-you">
          Thank you for your understanding as we work to serve you better.
        </p>
      </div>
    </div>
  );
};

const Finances = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [modifiedBrandName, setModifiedBrandName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (location.pathname === '/inventory/finances') {
            navigate('/inventory/finances/accounting');
        }
    }, [location, navigate]);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1200);
        if (window.innerWidth > 1200) {
          setIsMobileMenuOpen(false);
        }
      };
      
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchStoreData = async () => {
            const auth = getAuth();
            if (auth.currentUser) {
                const db = getFirestore();
                const storesRef = collection(db, 'stores');
                const q = query(storesRef, where('userId', '==', auth.currentUser.uid));
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const storeData = querySnapshot.docs[0].data();
                    setModifiedBrandName(storeData.modifiedBrandName);
                }
            }
            setLoading(false);
        };

        fetchStoreData();
    }, []);

    const handleInventoryClick = () => {
        navigate('/inventory/dashboard');
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="finances-page">
            <nav className='finances-navbar'>
                <div className="navbar-container">
                    <div className="navbar-left">
                        <div className="navbar-logo">
                            <Link to="/inventory/finances/accounting">
                                <img src={logoFinances} alt="DAXTOP FINANCES" />
                            </Link>
                        </div>
                        {!isMobileView && (
                            <ul className="navbar-links">
                                <li><Link to="/inventory/finances/accounting" className={isActive('/inventory/finances/accounting')}> Accounting</Link></li>
                                <li><Link to="/inventory/finances/budgeting" className={isActive('/inventory/finances/budgeting')}> Budgeting</Link></li>
                                <li><Link to="/inventory/finances/credit-management" className={isActive('/inventory/finances/credit-management')}> Credit Management</Link></li>
                                <li><Link to="/inventory/finances/payments" className={isActive('/inventory/finances/payments')}> Payments</Link></li>
                                <li><Link to="/inventory/finances/reports" className={isActive('/inventory/finances/reports')}> Financial Reports</Link></li>
                            </ul>
                        )}
                    </div>
                    <div className="navbar-actions">
                        {isMobileView ? (
                            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                                <Menu size={24} />
                            </button>
                        ) : (
                            <button className="inventory-btn" onClick={handleInventoryClick}> Go to Inventory</button>
                        )}
                    </div>
                </div>
            </nav>
            <MobileMenuFinances isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
            <div className="finances-content">
                <FinancesOverlay />
            </div>
        </div>
    );
};

export default Finances;

// Add this CSS to your Finances.css file or include it in a style tag
const styles = `
.finances-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 60px); /* Adjust based on your navbar height */
  background-color: rgba(245, 247, 250, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.finances-overlay-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.finances-overlay-content .alert-icon {
  color: #f59e0b;
  margin-bottom: 20px;
}

.finances-overlay-content h2 {
  color: #4b5563;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
}

.finances-overlay-content p {
  color: #4b5563;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.finances-overlay-content .support-email {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #2563eb;
  font-size: 18px;
  text-decoration: none;
  margin-top: 10px;
  padding: 10px 20px;
  border: 2px solid #2563eb;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.finances-overlay-content .support-email:hover {
  background-color: #2563eb;
  color: white;
}

.finances-overlay-content .thank-you {
  margin-top: 25px;
  font-style: italic;
  color: #6b7280;
}

.finances-content {
  position: relative;
  height: calc(100vh - 60px); /* Adjust based on your navbar height */
}
`;

// You can add this style to your document's head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);