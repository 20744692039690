import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, addDoc, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './InventoryManagement.css';
import { ArrowUpDown } from 'lucide-react'

const InventoryManagement = () => {
 const [inventory, setInventory] = useState([]);
 const [filteredInventory, setFilteredInventory] = useState([]);
 const [searchTerm, setSearchTerm] = useState('');
 const [filterCategory, setFilterCategory] = useState('All Categories');
 const [filterBrand, setFilterBrand] = useState('All Brands');
 const [filterStore, setFilterStore] = useState('All Stores');
 const [filterLocation, setFilterLocation] = useState('All Locations');
 const [inStockOnly, setInStockOnly] = useState(false);
 const [brandName, setBrandName] = useState('');
 const [stores, setStores] = useState([]);
 const [categories, setCategories] = useState(['All Categories', 'Raw Materials', 'Groceries', 'Beverages', 'Household Products', 'Personal Care Items', 'Snacks', 'Frozen Foods', 'Health and Wellness Products', 'Electronics', 'Pet Supplies', 'Office Supplies']);
 const [brands, setBrands] = useState(['All Brands']);
 const [hoveredItem, setHoveredItem] = useState(null);
 const [sortBy, setSortBy] = useState('Expiry Date');
 const [sortOrder, setSortOrder] = useState('Ascending');
 const [modifiedBrandName, setModifiedBrandName] = useState('');


 const navigate = useNavigate();
 const auth = getAuth();
 const db = getFirestore();

 useEffect(() => {
   fetchBrandNameAndStores();
 }, []);

 useEffect(() => {
   if (brandName && stores.length > 0) {
     fetchAllStoresItems();
   }
 }, [brandName, stores]);

 useEffect(() => {
   filterAndSortInventory();
 }, [inventory, searchTerm, filterCategory, filterBrand, filterLocation, filterStore, inStockOnly, sortBy, sortOrder]);

 const fetchBrandNameAndStores = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const storeDoc = await getDocs(query(collection(db, 'stores'), where('userId', '==', user.uid)));
      if (!storeDoc.empty) {
        const storeData = storeDoc.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);

        const networkStoresSnapshot = await getDocs(collection(db, `stores/${storeData.modifiedBrandName}/networkStores`));
        const storeAddresses = networkStoresSnapshot.docs.map(doc => doc.id);
        setStores(storeAddresses);
      }
    }
  } catch (error) {
    console.error("Error fetching brand name and stores:", error);
  }
};


const fetchAllStoresItems = async () => {
  try {
    let allItems = [];
    for (let store of stores) {
      const q = query(collection(db, `stores/${modifiedBrandName}/networkStores/${store}/items`));
      const querySnapshot = await getDocs(q);
      const storeItems = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        status: getItemStatus(doc.data()),
        store: store,
        dateAdded: doc.data().dateAdded ? doc.data().dateAdded.toDate() : new Date(),
        expiryDateTo: doc.data().expiryDateTo ? new Date(doc.data().expiryDateTo) : null
      }));
      allItems = [...allItems, ...storeItems];
    }
    setInventory(allItems);
    setFilteredInventory(allItems);
    updateBrands(allItems);
  } catch (error) {
    console.error("Error fetching items:", error);
  }
};


 const updateBrands = (items) => {
   const uniqueBrands = ['All Brands', ...new Set(items.map(item => item.brand))];
   setBrands(uniqueBrands);
 };

 const getItemStatus = (item) => {
   const today = new Date();
   const expirationDate = item.expiryDateTo ? new Date(item.expiryDateTo) : null;

   if (item.quantity === 0) {
    sendNotification(item, 'Not Available');
    return 'Not Available';
  } else if (expirationDate && expirationDate < today && !item.noExpiryDate) {
    sendNotification(item, 'Expired Date');
    return 'Expired';
  } else if (item.quantity < item.lowStockThreshold) {
    sendNotification(item, 'Low Stock Threshold');
    return 'Low Stock';
  } else {
    return 'Available';
  }
 };

 const sendNotification = async (item, status) => {
  try {
    await addDoc(collection(db, `stores/${modifiedBrandName}/notifications`), {
      title: status,
      store: item.store,
      item: item.name,
      amount: item.quantity,
      status: status,
      date: new Date()
    });
  } catch (error) {
    console.error('Error sending notification:', error);
  }
};

 const filterAndSortInventory = () => {
   let filtered = inventory.filter(item => {
     return item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
       (filterCategory === 'All Categories' || item.category === filterCategory) &&
       (filterBrand === 'All Brands' || item.brand === filterBrand) &&
       (filterStore === 'All Stores' || item.store === filterStore) &&
       (filterLocation === 'All Locations' || item.location === filterLocation) &&
       (!inStockOnly || item.quantity > 0);
   });
 
   filtered.sort((a, b) => {
     let comparison = 0;
 
     const compareDates = (dateA, dateB) => {
       if (!dateA && !dateB) return 0;
       if (!dateA) return 1;
       if (!dateB) return -1;
       return dateA.getTime() - dateB.getTime();
     };
 
     switch (sortBy) {
       case 'Alphabetical':
         comparison = a.name.localeCompare(b.name);
         break;
       case 'Expiry Date':
         comparison = compareDates(a.expiryDateTo, b.expiryDateTo);
         break;
       case 'Date Added':
         comparison = compareDates(a.dateAdded, b.dateAdded);
         break;
       case 'Quantity':
         comparison = b.quantity - a.quantity;
         break;
       case 'Price':
         comparison = b.price - a.price;
         break;
       default:
         comparison = 0;
     }
     return sortOrder === 'Ascending' ? comparison : -comparison;
   });
 
   setFilteredInventory(filtered);
 };

 const toggleSortOrder = () => {
   setSortOrder(prevOrder => prevOrder === 'Ascending' ? 'Descending' : 'Ascending');
 };

 const handleTakeOut = (item) => {
  if (modifiedBrandName) {
    navigate('/inventory/takeout', { state: { selectedStore: item.store, selectedItem: item.id, modifiedBrandName: modifiedBrandName } });
  } else {
    console.error("Modified brand name is not set");
  }
};


 return (
   <div className="inventory-management">
     <div className="main-content">
       <div className="left-panel">
         <div className="search-section">
           <input
             className='search-input'
             type="text"
             placeholder="Search inventory"
             value={searchTerm}
             onChange={(e) => setSearchTerm(e.target.value)}
           />
           <select value={filterCategory} onChange={(e) => setFilterCategory(e.target.value)}>
             {categories.map(category => (
               <option key={category} value={category}>{category}</option>
             ))}
           </select>
         </div>
         <div className="sort-section">
           <span>Sort by:</span>
           <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
             <option value="Alphabetical">Alphabetical</option>
             <option value="Date Added">Date Added</option>
             <option value="Expiry Date">Expiry Date</option>
             <option value="Quantity">Quantity</option>
             <option value="Price">Price</option>
           </select>
           <button onClick={toggleSortOrder} className="sort-order-button">
             <ArrowUpDown size={20} />
             {sortOrder}
           </button>
         </div>
         <div className="inventory-list">
           <h2>Inventory List</h2>
           <div className="filter-buttons">
             <button onClick={() => setFilterLocation('All Locations')}>Location Filter</button>
             <button onClick={() => setFilterCategory('All Categories')}>Category Filter</button>
             <button onClick={() => setInStockOnly(!inStockOnly)}>In Stock Filter</button>
           </div>
           <div className="inventory-header">
             <div className="item-details">Item Details</div>
             <div className="item-quantity">Available quantity</div>
             <div className="item-price">Price</div>
             <div className="item-addedDate">Added to Store</div>
             <div className="item-date">Expiration Date</div>
             <div className="item-status">Status</div>
           </div>
           {filteredInventory.map((item) => (
             <div
               key={item.id}
               className="inventory-item"
               onMouseEnter={() => setHoveredItem(item.id)}
               onMouseLeave={() => setHoveredItem(null)}
             >
               <div className="item-details">
                 <h3>{item.name.length > 20 ? `${item.name.substring(0, 20)}...` : item.name}</h3>
                 <p>Category: {item.category.length > 20 ? `${item.category.substring(0, 20)}...` : item.category}</p>
                 <p>Brand: {item.brand.length > 20 ? `${item.brand.substring(0, 20)}...` : item.brand}</p>
                 <p>Volume: {item.volume ? (item.volume.length > 25 ? `${item.volume.substring(0, 25)}...` : item.volume) : 'N/A'} {item.unitOfMeasureForVolume !== "N/A" ? item.unitOfMeasureForVolume : ''}</p>
                 <p>Weight: {item.weight ? (item.weight.length > 25 ? `${item.weight.substring(0, 25)}...` : item.weight) : 'N/A'} {item.unitOfMeasureForWeight !== "N/A" ? item.unitOfMeasureForWeight : ''}</p>
                 <p>Store Address: {item.store.length > 20 ? `${item.store.substring(0, 20)}...` : item.store}</p>
               </div>
               <div className="item-quantity">
                 <span className={`quantity-badge ${item.quantity < item.lowStockThreshold && item.quantity !== 0 ? 'below-stock' : item.quantity === 0 ? 'out-of-stock' : ''}`}>
                   {item.quantity}
                 </span>    
               </div>
               <div className="item-price">
                 <span>${parseFloat(item.price).toFixed(2)}</span>
               </div>
               <div className="item-addedDate">
                 <span>{item.dateAdded.toLocaleDateString()}</span>
               </div>
               <div className="item-date">
                 <span>{item.noExpiryDate ? 'N/A' : (item.expiryDateTo ? item.expiryDateTo.toLocaleDateString() : 'N/A')}</span>
               </div>
               <div className="item-status">
                 <span>{item.status}</span>
               </div>
               {hoveredItem === item.id && (
                 <button
                   className="take-out-button"
                   onClick={() => handleTakeOut(item)}
                 >
                   Take Out
                 </button>
               )}
             </div>
           ))}
         </div>
       </div>
       <div className="right-panel">
         <div className="filters-section">
           <h2>Inventory Filters</h2>
           <div className="filter">
             <label>Filter By Store</label>
             <select value={filterStore} onChange={(e) => setFilterStore(e.target.value)}>
               <option>All Stores</option>
               {stores.map(store => (
                 <option key={store} value={store}>{store}</option>
               ))}
             </select>
           </div>
           <div className="filter">
             <label>Filter By Brand</label>
             <select value={filterBrand} onChange={(e) => setFilterBrand(e.target.value)}>
               {brands.map(brand => (
                 <option key={brand} value={brand}>{brand}</option>
               ))}
             </select>
           </div>
           <div className="filter">
             <label>Filter By Location</label>
             <select value={filterLocation} onChange={(e) => setFilterLocation(e.target.value)}>
               <option>All Locations</option>
             </select>
           </div>
           <div className="filter toggle-switch">
             <label htmlFor="inStock">
               <input
                 type="checkbox"
                 id="inStock"
                 checked={inStockOnly}
                 onChange={(e) => setInStockOnly(e.target.checked)}
               />
               <span className="slider round"></span>
               <span className="toggle-label">Only Items In Stock</span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default InventoryManagement;