import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, User, Search, ChevronRight } from 'lucide-react';
import logoBlue from './Images/logoBlue.webp';
import image from './Images/image-office.webp';
import globe from './Images/glob.png';
import './LandingPage.css';
import { translations, regions, countryToISOCode } from './Data/data';
import MobileMenu from './MobileMenu';

const APP_NAME = "DAXTOP PORTAL"

const LandingPage = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showRegionSelector, setShowRegionSelector] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('en');
  const [userCountry, setUserCountry] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const navigate = useNavigate();
  const contactUsRef = useRef(null);
  const regionSelectorRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);



  const handleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
 };

 

  const handleLogin = () => {
    navigate('/login');
 };

  const toggleContactUs = () => {
    setShowContactUs(prev => !prev);
    setShowRegionSelector(false);
 };

 useEffect(() => {
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 1200);
    if (window.innerWidth > 1200) {
      setIsMobileMenuOpen(false);
    }
  };
  
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => window.removeEventListener('resize', handleResize);
}, []);


  const handleRegionSelectorToggle = () => {
    setShowRegionSelector(!showRegionSelector);
    if (!showRegionSelector) {
      setSearchQuery('');
 }
 };

  const handleClickOutside = useCallback((event) => {
    if (contactUsRef.current && !contactUsRef.current.contains(event.target)) {
      setShowContactUs(false);
 }
    if (regionSelectorRef.current && !regionSelectorRef.current.contains(event.target)) {
      setShowRegionSelector(false);
 }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
 }
 }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
 };
 }, [handleClickOutside]);

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const country = regions.flatMap(r => r.countries).find(c => c.name === data.country_name);
        if (country) {
          setUserCountry(country);
          setLanguage(country.code);
 } else {
          setLanguage('en');
 }
 } catch (error) {
        console.error('Error fetching user location:', error);
        setLanguage('en');
 }
 };

    getUserLocation();
 }, []);

  const handleCountrySelect = (country) => {
    setUserCountry(country);
    setLanguage(country.code);
    setShowRegionSelector(false);
    setSearchQuery('');
 };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
 };


  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showRegionSelector && e.key.length === 1 && e.key.match(/[a-z]/i)) {
        setSearchQuery(prevQuery => prevQuery);
        if (searchInputRef.current) {
          searchInputRef.current.focus();
 }
 }
 };
  
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
 };
 }, [showRegionSelector]);


  const filteredRegions = regions.map(region => ({
    ...region,
    countries: region.countries.filter(country =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
 )
 })).filter(region => region.countries.length > 0);

  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
 };

  const getCountryFlag = (countryName) => {
    const isoCode = countryToISOCode[countryName] || countryName.toLowerCase();
    return `https://flagcdn.com/w160/${isoCode}.png`;
 };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
 };

  const handleBlur = () => {
    setErrorMessage('');
 };

  const handleSubmit = () => {
    if (inputValue === 'Q0rt444asdf15k357') {
      navigate('/inventory/register/Q0rt444asdf15k357');
 } else {
      setErrorMessage('Wrong Registration Code');
 }
 };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
 };

  const handleMarketResearchClick = (e) => {
    e.preventDefault();
    window.location.href = 'https://market.daxtop.com/search';
 };
    
  
  return (
    <div className="landing-page">
    <header className="landing-header">
      <div className={`header-content ${isMobileView ? 'header-content-mobile' : 'header-content-desktop'}`}>
        <div className="header-left">
          <div className="logo">
            <img src={logoBlue} alt="DAXTOP Logo" />
          </div>
          {!isMobileView && (
            <nav className="landing-nav">
              <ul>
                <li 
                  onMouseEnter={() => handleDropdown('products')} 
                  onMouseLeave={() => handleDropdown(null)}
                  className={activeDropdown === 'products' ? 'active' : ''}
                >
                  <span>{translate('products')}</span>
                  {activeDropdown === 'products' && (
                    <div className="dropdown-content" ref={dropdownRef}>
                        <div className="dropdown-column">
                          <h3>{translate('by_category')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li onClick={handleLogin}>{translate('inventory_management')}</li>
                            <li>{translate('supply_chain_management')}</li>
                            <li>{translate('financial_management')}</li>
                            <li>{translate('human_capital_management')}</li>
                            <li>{translate('crm_and_customer_experience')}</li>
                            <li>{translate('view_products_az')}</li>
                          </ul>
                        </div>
                        <div className="dropdown-column">
                          <h3>{translate('by_industry')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li>{translate('automotive')}</li>
                            <li>{translate('banking')}</li>
                            <li>{translate('consumer_products')}</li>
                            <li>{translate('healthcare')}</li>
                            <li>{translate('retail')}</li>
                            <li>{translate('view_all_industries')}</li>
                          </ul>
                        </div>
                        <div className="dropdown-column">
                          <h3>{translate('business_technology_platform')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li>{translate('application_development_and_automation')}</li>
                            <li>{translate('data_and_analytics')}</li>
                            <li>{translate('extended_planning_and_analysis')}</li>
                            <li>{translate('integration')}</li>
                          </ul>
                        </div>
                      </div>
 )}
                  </li>
                  <li><a href="https://market.daxtop.com/search" onClick={handleMarketResearchClick}>{translate('market_research')}</a></li>         
                  <li><a href="/learning">{translate('learning')}</a></li>
                  <li><a href="/community">{translate('community')}</a></li>
                  <li><a href="/partners">{translate('partners')}</a></li>
                  <li><a href="/about">{translate('about')}</a></li>
                </ul>
              </nav>
 )}
          </div>
          <div className="header-right">
            {!isMobileView && (
              <button className="login-button" onClick={handleLogin}>{translate('login')}</button>
 )}
            <div className="country-icon" onClick={handleRegionSelectorToggle}>
              {userCountry ? (
                <img
                  src={getCountryFlag(userCountry.name)}
                  alt={`${userCountry.name} flag`}
                  className="country-flag-circle"
                />
 ) : (
                <img src={globe} alt="Globe" className="country-flag-circle" />
 )}
            </div>
            {isMobileView && (
              <div className="mobile-header-right">
                <User size={22} color="#0070f2" onClick={handleLogin} />
                <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                  <Menu size={23} color="#0070f2" />
                  <span className="sr-only">Menu</span>
                </button>
              </div>
 )}
          </div>
        </div>
      </header>
      
      <MobileMenu 
        isOpen={isMobileMenuOpen} 
        onClose={toggleMobileMenu} 
        translate={translate}
        handleLogin={handleLogin}
      />

{showRegionSelector && (
        <div className="region-selector-overlay">
          <div className="region-selector" ref={regionSelectorRef}>
            <div className="region-selector-p" >
            <p>{translate('choose_region')}</p>
            </div>
            <input
              ref={searchInputRef}
              type="text"
              placeholder={translate('search_country')}
              value={searchQuery}
              onChange={handleSearch}
              className="country-search"
            />
            <div className="region-container">
              {filteredRegions.map((region) => (
                <div key={region.name} className="region">
                  <h4>{region.name}</h4>
                  <ul>
                    {region.countries.map((country) => (
                      <li key={country.name} onClick={() => handleCountrySelect(country)}>
                        <img 
                          src={getCountryFlag(country.name)}
                          alt={`${country.name} flag`}
                          className="country-flag"
                        />
                        {country.name}
                      </li>
 ))}
                  </ul>
                </div>
 ))}
            </div>
          </div>
        </div>
 )}
      
      <main className="landing-main">
        <div className="hero-section">
          <div className="hero-image">
            <img src={image} alt="AI Innovation" />
          </div>
          <div className="hero-content">
            <h1>{translate('ai_innovations')}</h1>
            <p>{translate('discover_text')}</p>
            <button className="cta-button">{translate('learn_more')}</button>
          </div>
        </div>
        <div className="background-overlay">
          <div className="centered-container">
            <div className="welcome-message">
              <h1>WELCOME TO {APP_NAME}</h1>
              <hr />
              <h2>Thank you for joining our Beta Test and contributing feedback. Please enter the Promocode below to proceed.</h2>
              <input
                type="text"
                placeholder="Enter the code..."
                value={inputValue}
                onChange={handleInputChange}
                className={`input-field ${errorMessage ? 'error-active' : ''}`}
                onBlur={handleBlur}
              />
              {errorMessage && <p className="error-message-1">{errorMessage}</p>}
              <button onClick={handleSubmit} className="submit-button">Submit</button>
              <p className="support-contact">Already Registered? <a href="/login">Sign in</a></p>
              <p className="support-contact">If you face any issues regarding the registration process, please contact <a href="mailto:support@daxtop.com">support@daxtop.com</a></p>
            </div>
          </div>
        </div>

        
      </main>
  
      <div className="contact-us-button" onClick={toggleContactUs}>
        {translate('contact_us')}
      </div>
  
      {showContactUs && (
        <div className="contact-us-popup" ref={contactUsRef}>
          <h3>{translate('call_us_at')}</h3>
          <p className="phone-number">{translate('phone_number')}</p>
          <p className="subtext">{translate('complete_list_of_local_numbers')}</p>
          <div className="contact-options">
            <div className="option">
              <h4>{translate('chat_offline')}</h4>
              <p>{translate('chat_offline_description')}</p>
            </div>
            <div className="option">
              <h4>{translate('contact_us')}</h4>
              <p>{translate('contact_us_description')}</p>
            </div>
          </div>
        </div>
 )}

      
    </div>
 );
};

export default LandingPage;
