
export const translations = {
  en: {
    products: 'Products',
    market_research: 'Market Research',
    learning: 'Learning',
    community: 'Community',
    partners: 'Partners',
    about: 'About',
    login: 'Login',
    contact_us: 'Contact us',
    ai_innovations: 'AI innovations at Your Company',
    discover_text: 'Discover how the latest in generative AI capabilities in our solutions portfolio can help you revolutionize your business model strategy.',
    learn_more: 'Learn more',
    choose_region: 'Choose your Region',
    search_country: 'Search for a country',
    inventory_management: 'Inventory Management',
    supply_chain_management: 'Supply Chain Management',
    financial_management: 'Financial Management',
    human_capital_management: 'Human Capital Management',
    crm_and_customer_experience: 'CRM and Customer Experience',
    view_products_az: 'View Products A-Z',
    by_industry: 'By Industry',
    by_category: 'By Category',
    automotive: 'Automotive',
    banking: 'Banking',
    consumer_products: 'Consumer Products',
    healthcare: 'Healthcare',
    retail: 'Retail',
    view_all_industries: 'View All Industries',
    business_technology_platform: 'Business Technology Platform',
    application_development_and_automation: 'Application Development and Automation',
    data_and_analytics: 'Data and Analytics',
    extended_planning_and_analysis: 'Extended Planning and Analysis',
    integration: 'Integration',
},

zh: {
    products: '产品',
    market_research: '市场研究',
    learning: '学习',
    community: '社区',
    partners: '合作伙伴',
    about: '关于',
    login: '登录',
    contact_us: '联系我们',
    ai_innovations: 'YourCompany的AI创新',
    discover_text: '了解我们解决方案组合中最新的生成式AI功能如何帮助您革新业务模型策略。',
    learn_more: '了解更多',
    choose_region: '选择您的地区',
    search_country: '搜索国家',
    inventory_management: '库存管理',
    supply_chain_management: '供应链管理',
    financial_management: '财务管理',
    human_capital_management: '人力资本管理',
    crm_and_customer_experience: 'CRM与客户体验',
    view_products_az: '查看产品 A-Z',
    by_industry: '按行业',
    by_category: '按类别',
    automotive: '汽车',
    banking: '银行',
    consumer_products: '消费品',
    healthcare: '医疗保健',
    retail: '零售',
    view_all_industries: '查看所有行业',
    business_technology_platform: '商业技术平台',
    application_development_and_automation: '应用开发与自动化',
    data_and_analytics: '数据与分析',
    extended_planning_and_analysis: '扩展规划与分析',
    integration: '集成',
},

es: {
    products: 'Productos',
    market_research: 'Investigación de Mercado',
    learning: 'Aprendizaje',
    community: 'Comunidad',
    partners: 'Socios',
    about: 'Acerca de',
    login: 'Iniciar sesión',
    contact_us: 'Contáctenos',
    ai_innovations: 'Innovaciones de IA en YourCompany',
    discover_text: 'Descubra cómo las últimas capacidades de IA generativa en nuestra cartera de soluciones pueden ayudarlo a revolucionar su estrategia de modelo de negocio.',
    learn_more: 'Aprenda más',
    choose_region: 'Elija su región',
    search_country: 'Buscar un país',
    inventory_management: 'Gestión de inventario',
    supply_chain_management: 'Gestión de la cadena de suministro',
    financial_management: 'Gestión financiera',
    human_capital_management: 'Gestión del capital humano',
    crm_and_customer_experience: 'CRM y experiencia del cliente',
    view_products_az: 'Ver productos A-Z',
    by_industry: 'Por industria',
    by_category: 'Por categoría',
    automotive: 'Automotriz',
    banking: 'Bancario',
    consumer_products: 'Productos de consumo',
    healthcare: 'Salud',
    retail: 'Minorista',
    view_all_industries: 'Ver todas las industrias',
    business_technology_platform: 'Plataforma de tecnología empresarial',
    application_development_and_automation: 'Desarrollo y automatización de aplicaciones',
    data_and_analytics: 'Datos y análisis',
    extended_planning_and_analysis: 'Planificación y análisis extendidos',
    integration: 'Integración',
},

fr: {
    products: 'Produits',
    market_research: 'Étude de Marché',
    learning: 'Apprentissage',
    community: 'Communauté',
    partners: 'Partenaires',
    about: 'À propos',
    login: 'Connexion',
    contact_us: 'Contactez-nous',
    ai_innovations: 'Innovations IA chez YourCompany',
    discover_text: 'Découvrez comment les dernières capacités d\'IA générative de notre portefeuille de solutions peuvent vous aider à révolutionner votre stratégie de modèle d\'entreprise.',
    learn_more: 'En savoir plus',
    choose_region: 'Choisissez votre région',
    search_country: 'Rechercher un pays',
    inventory_management: 'Gestion des stocks',
    supply_chain_management: 'Gestion de la chaîne d\'approvisionnement',
    financial_management: 'Gestion financière',
    human_capital_management: 'Gestion des ressources humaines',
    crm_and_customer_experience: 'CRM et expérience client',
    view_products_az: 'Voir les produits A-Z',
    by_industry: 'Par industrie',
    by_category: 'Par catégorie',
    automotive: 'Automobile',
    banking: 'Banque',
    consumer_products: 'Produits de consommation',
    healthcare: 'Santé',
    retail: 'Vente au détail',
    view_all_industries: 'Voir toutes les industries',
    business_technology_platform: 'Plateforme de technologie d\'entreprise',
    application_development_and_automation: 'Développement et automatisation d\'applications',
    data_and_analytics: 'Données et analyses',
    extended_planning_and_analysis: 'Planification et analyse étendues',
    integration: 'Intégration',
},

ar: {
    products: 'المنتجات',
    market_research: 'بحث السوق ',
    learning: 'التعلم',
    community: 'المجتمع',
    partners: 'الشركاء',
    about: 'حول',
    login: 'تسجيل الدخول',
    contact_us: 'اتصل بنا',
    ai_innovations: 'ابتكارات الذكاء الاصطناعي في YourCompany',
    discover_text: 'اكتشف كيف يمكن لأحدث قدرات الذكاء الاصطناعي التوليدي في محفظة حلولنا أن تساعدك في ثورة استراتيجية نموذج أعمالك.',
    learn_more: 'تعرف على المزيد',
    choose_region: 'اختر منطقتك',
    search_country: 'البحث عن بلد',
    inventory_management: 'إدارة المخزون',
    supply_chain_management: 'إدارة سلسلة التوريد',
    financial_management: 'الإدارة المالية',
    human_capital_management: 'إدارة رأس المال البشري',
    crm_and_customer_experience: 'إدارة علاقات العملاء وتجربة العملاء',
    view_products_az: 'عرض المنتجات A-Z',
    by_industry: 'حسب الصناعة',
    by_category: 'حسب الفئة',
    automotive: 'سيارات',
    banking: 'البنوك',
    consumer_products: 'المنتجات الاستهلاكية',
    healthcare: 'الرعاية الصحية',
    retail: 'تجزئة',
    view_all_industries: 'عرض جميع الصناعات',
    business_technology_platform: 'منصة تكنولوجيا الأعمال',
    application_development_and_automation: 'تطوير التطبيقات والأتمتة',
    data_and_analytics: 'البيانات والتحليلات',
    extended_planning_and_analysis: 'التخطيط والتحليل الموسع',
    integration: 'التكامل',
},

hi: {
    products: 'उत्पाद',
    market_research: 'बाज़ार अनुसंधान',
    learning: 'सीखना',
    community: 'समुदाय',
    partners: 'साझेदार',
    about: 'के बारे में',
    login: 'लॉग इन करें',
    contact_us: 'संपर्क करें',
    ai_innovations: 'YourCompany में AI नवाचार',
    discover_text: 'जानें कि हमारे समाधान पोर्टफोलियो में नवीनतम जनरेटिव AI क्षमताएं आपके व्यवसाय मॉडल रणनीति को कैसे क्रांतिकारी बना सकती हैं।',
    learn_more: 'और जानें',
    choose_region: 'अपना क्षेत्र चुनें',
    search_country: 'देश खोजें',
    inventory_management: 'इन्वेंटरी प्रबंधन',
    supply_chain_management: 'सप्लाई चेन प्रबंधन',
    financial_management: 'वित्तीय प्रबंधन',
    human_capital_management: 'मानव पूंजी प्रबंधन',
    crm_and_customer_experience: 'CRM और ग्राहक अनुभव',
    view_products_az: 'उत्पाद देखें A-Z',
    by_industry: 'उद्योग द्वारा',
    by_category: 'श्रेणी द्वारा',
    automotive: 'ऑटोमोटिव',
    banking: 'बैंकिंग',
    consumer_products: 'उपभोक्ता उत्पाद',
    healthcare: 'स्वास्थ्य सेवा',
    retail: 'खुदरा',
    view_all_industries: 'सभी उद्योग देखें',
    business_technology_platform: 'व्यापार तकनीकी प्लेटफ़ॉर्म',
    application_development_and_automation: 'एप्लिकेशन विकास और स्वचालन',
    data_and_analytics: 'डेटा और विश्लेषण',
    extended_planning_and_analysis: 'विस्तारित योजना और विश्लेषण',
    integration: 'एकीकरण',
},

ru: {
    products: 'Продукты',
    market_research: 'Исследование Рынка',
    learning: 'Обучение',
    community: 'Сообщество',
    partners: 'Партнеры',
    about: 'О нас',
    login: 'Вход',
    contact_us: 'Свяжитесь с нами',
    ai_innovations: 'Инновации ИИ в YourCompany',
    discover_text: 'Узнайте, как новейшие возможности генеративного ИИ в нашем портфеле решений могут помочь вам революционизировать стратегию вашей бизнес-модели.',
    learn_more: 'Узнать больше',
    choose_region: 'Выберите свой регион',
    search_country: 'Поиск страны',
    inventory_management: 'Управление запасами',
    supply_chain_management: 'Управление цепочкой поставок',
    financial_management: 'Финансовое управление',
    human_capital_management: 'Управление человеческим капиталом',
    crm_and_customer_experience: 'CRM и клиентский опыт',
    view_products_az: 'Посмотреть продукты A-Z',
    by_industry: 'По отраслям',
    by_category: 'По категории',
    automotive: 'Автомобильный',
    banking: 'Банковский',
    consumer_products: 'Потребительские товары',
    healthcare: 'Здравоохранение',
    retail: 'Розничная торговля',
    view_all_industries: 'Посмотреть все отрасли',
    business_technology_platform: 'Платформа бизнес-технологий',
    application_development_and_automation: 'Разработка и автоматизация приложений',
    data_and_analytics: 'Данные и аналитика',
    extended_planning_and_analysis: 'Расширенное планирование и анализ',
    integration: 'Интеграция',
},

pt: {
    products: 'Produtos',
    market_research: 'Pesquisa de Mercado',
    learning: 'Aprendizado',
    community: 'Comunidade',
    partners: 'Parceiros',
    about: 'Sobre',
    login: 'Entrar',
    contact_us: 'Contate-nos',
    ai_innovations: 'Inovações de IA na YourCompany',
    discover_text: 'Descubra como as mais recentes capacidades de IA generativa em nosso portfólio de soluções podem ajudá-lo a revolucionar sua estratégia de modelo de negócios.',
    learn_more: 'Saiba mais',
    choose_region: 'Escolha sua região',
    search_country: 'Pesquisar um país',
    inventory_management: 'Gestão de Inventário',
    supply_chain_management: 'Gestão da Cadeia de Suprimentos',
    financial_management: 'Gestão Financeira',
    human_capital_management: 'Gestão de Capital Humano',
    crm_and_customer_experience: 'CRM e Experiência do Cliente',
    view_products_az: 'Ver Produtos A-Z',
    by_industry: 'Por Indústria',
    by_category: 'Por Categoria',
    automotive: 'Automotivo',
    banking: 'Bancário',
    consumer_products: 'Produtos de Consumo',
    healthcare: 'Saúde',
    retail: 'Varejo',
    view_all_industries: 'Ver Todas as Indústrias',
    business_technology_platform: 'Plataforma de Tecnologia Empresarial',
    application_development_and_automation: 'Desenvolvimento e Automação de Aplicativos',
    data_and_analytics: 'Dados e Análise',
    extended_planning_and_analysis: 'Planejamento e Análise Avançados',
    integration: 'Integração',
},

bn: {
    products: 'পণ্য',
    market_research: 'বাজার গবেষণা',
    learning: 'শেখা',
    community: 'সম্প্রদায়',
    partners: 'পার্টনার',
    about: 'সম্পর্কে',
    login: 'লগ ইন করুন',
    contact_us: 'যোগাযোগ করুন',
    ai_innovations: 'YourCompany-এ AI উদ্ভাবন',
    discover_text: 'জানুন কীভাবে আমাদের সমাধান পোর্টফোলিওতে সর্বশেষ জেনারেটিভ AI ক্ষমতাগুলি আপনার ব্যবসার মডেল কৌশলকে বিপ্লবী করতে সহায়তা করতে পারে।',
    learn_more: 'আরও জানুন',
    choose_region: 'আপনার অঞ্চল নির্বাচন করুন',
    search_country: 'একটি দেশ খুঁজুন',
    inventory_management: 'মজুদ ব্যবস্থাপনা',
    supply_chain_management: 'সরবরাহ শৃঙ্খলা ব্যবস্থাপনা',
    financial_management: 'আর্থিক ব্যবস্থাপনা',
    human_capital_management: 'মানবসম্পদ ব্যবস্থাপনা',
    crm_and_customer_experience: 'CRM এবং গ্রাহক অভিজ্ঞতা',
    view_products_az: 'পণ্য দেখুন A-Z',
    by_industry: 'শিল্প দ্বারা',
    by_category: 'শ্রেণী অনুযায়ী',
    automotive: 'অটোমোটিভ',
    banking: 'ব্যাংকিং',
    consumer_products: 'ভোক্তাদের পণ্য',
    healthcare: 'স্বাস্থ্যসেবা',
    retail: 'খুচরা',
    view_all_industries: 'সব শিল্প দেখুন',
    business_technology_platform: 'ব্যবসা প্রযুক্তির প্ল্যাটফর্ম',
    application_development_and_automation: 'অ্যাপ্লিকেশন উন্নয়ন এবং স্বয়ংক্রিয়তা',
    data_and_analytics: 'ডেটা এবং বিশ্লেষণ',
    extended_planning_and_analysis: 'বিস্তৃত পরিকল্পনা এবং বিশ্লেষণ',
    integration: 'একীভূতকরণ',
},

ja: {
    products: '製品',
    market_research: '市場調査',
    learning: '学習',
    community: 'コミュニティ',
    partners: 'パートナー',
    about: '私たちについて',
    login: 'ログイン',
    contact_us: 'お問い合わせ',
    ai_innovations: 'YourCompanyのAI革新',
    discover_text: '私たちのソリューションポートフォリオにおける最新の生成AI機能が、どのようにあなたのビジネスモデル戦略を革命的に変えることができるかを発見してください。',
    learn_more: '詳細を学ぶ',
    choose_region: '地域を選択',
    search_country: '国を検索',
    inventory_management: '在庫管理',
    supply_chain_management: 'サプライチェーン管理',
    financial_management: '財務管理',
    human_capital_management: '人的資本管理',
    crm_and_customer_experience: 'CRMと顧客体験',
    view_products_az: '製品をA-Zで表示',
    by_industry: '業界別',
    by_category: 'カテゴリー別',
    automotive: '自動車',
    banking: '銀行',
    consumer_products: '消費財',
    healthcare: 'ヘルスケア',
    retail: '小売',
    view_all_industries: 'すべての業界を表示',
    business_technology_platform: 'ビジネステクノロジープラットフォーム',
    application_development_and_automation: 'アプリケーション開発と自動化',
    data_and_analytics: 'データと分析',
    extended_planning_and_analysis: '拡張計画と分析',
    integration: '統合',
},

pa: {
    products: 'ਉਤਪਾਦ',
    market_research: 'ਮਾਰਕੀਟ ਰਿਸਰਚ',
    learning: 'ਸਿੱਖਣਾ',
    community: 'ਕਮਿਊਨਿਟੀ',
    partners: 'ਭਾਗੀਦਾਰ',
    about: 'ਬਾਰੇ',
    login: 'ਲਾਗਿਨ ਕਰੋ',
    contact_us: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    ai_innovations: 'YourCompany ਵਿੱਚ AI ਨਵੀਨਤਾਵਾਂ',
    discover_text: 'ਜਾਣੋ ਕਿ ਸਾਡੇ ਹੱਲਾਂ ਦੇ ਪੋਰਟਫੋਲੀਓ ਵਿੱਚ ਨਵੀਨਤਮ ਜਨਰੇਟਿਵ AI ਸਮਰੱਥਾਵਾਂ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਮਾਡਲ ਦੀ ਰਣਨੀਤੀ ਨੂੰ ਕਿਵੇਂ ਬਦਲ ਸਕਦੀਆਂ ਹਨ।',
    learn_more: 'ਹੋਰ ਜਾਣੋ',
    choose_region: 'ਆਪਣਾ ਖੇਤਰ ਚੁਣੋ',
    search_country: 'ਇੱਕ ਦੇਸ਼ ਖੋਜੋ',
    inventory_management: 'ਇਨਵੈਂਟਰੀ ਪ੍ਰਬੰਧਨ',
    supply_chain_management: 'ਸਪਲਾਈ ਚੇਨ ਪ੍ਰਬੰਧਨ',
    financial_management: 'ਆਰਥਿਕ ਪ੍ਰਬੰਧਨ',
    human_capital_management: 'ਮਨੁੱਖੀ ਪੂੰਜੀ ਪ੍ਰਬੰਧਨ',
    crm_and_customer_experience: 'CRM ਅਤੇ ਗ੍ਰਾਹਕ ਅਨੁਭਵ',
    view_products_az: 'ਉਤਪਾਦਾਂ ਨੂੰ A-Z ਵੇਖੋ',
    by_industry: 'ਉਦਯੋਗ ਦੁਆਰਾ',
    by_category: 'ਸ਼੍ਰੇਣੀ ਦੁਆਰਾ',
    automotive: 'ਗੱਡੀਆਂ',
    banking: 'ਬੈਂਕਿੰਗ',
    consumer_products: 'ਉਪਭੋਗਤਾ ਉਤਪਾਦ',
    healthcare: 'ਸਿਹਤ ਸੇਵਾ',
    retail: 'ਹਿੱਸੇਦਾਰੀ',
    view_all_industries: 'ਸਭ ਉਦਯੋਗ ਵੇਖੋ',
    business_technology_platform: 'ਕਾਰੋਬਾਰ ਟੈਕਨੋਲੋਜੀ ਪਲੇਟਫਾਰਮ',
    application_development_and_automation: 'ਐਪਲੀਕੇਸ਼ਨ ਵਿਕਾਸ ਅਤੇ ਆਟੋਮੇਸ਼ਨ',
    data_and_analytics: 'ਡੇਟਾ ਅਤੇ ਵਿਸ਼ਲੇਸ਼ਣ',
    extended_planning_and_analysis: 'ਵਿਸ਼ਤਾਰਿਤ ਯੋਜਨਾ ਅਤੇ ਵਿਸ਼ਲੇਸ਼ਣ',
    integration: 'ਇਕਤੀ',
},
hy: {
  products: 'Ապրանքներ',
  market_research: 'Շուկայի Հետազոտություն',
  learning: 'Դասընթացներ',
  community: 'Համայնք',
  partners: 'Գործընկերներ',
  about: 'Մեր Մասին',
  login: 'Մուտք Գործել',
  contact_us: 'Հետադարձ Կապ',
  ai_innovations: 'Քո ընկերությունում AI նորարարություններ',
  discover_text: 'Որպեսզի իմանաք, թե մեր լուծումների պորտֆոլիոյում վերջին արտադրողական AI կարողությունները ինչպես կարող են օգնել ձեզ հեղափոխել ձեր բիզնես մոդելի ռազմավարությունը:',
  learn_more: 'Իմանալ Ավելին',
  choose_region: 'Ընտրեք Ձեր Տարածաշրջանը',
  search_country: 'Փնտրել Երկիր',
  inventory_management: 'Պահեստի Կառավարում',
  supply_chain_management: 'Մատակարարման Շղթայի Կառավարում',
  financial_management: 'Ֆինանսական Կառավարում',
  human_capital_management: 'Մարդկային Կապիտալի Կառավարում',
  crm_and_customer_experience: 'CRM և Հաճախորդի Գոհունակություն',
  view_products_az: 'Դիտել Ապրանքները Ա-Ֆ',
  by_industry: 'Ըստ Ոլորտի',
  by_category: 'Ըստ Կատեգորիայի ',
  automotive: 'Ավտոմոբիլային',
  banking: 'Բանկային Գործը',
  consumer_products: 'Սպառողական Ապրանքներ',
  healthcare: 'Առողջապահություն',
  retail: 'Մանրածախ',
  view_all_industries: 'Դիտել Բոլոր Ոլորտները',
  business_technology_platform: 'Բիզնեսի Տեխնոլոգիական Պլատֆորմ',
  application_development_and_automation: 'Հավելվածների Մշակում և Ավտոմատացում',
  data_and_analytics: 'Տվյալներ և Վերլուծություն',
  extended_planning_and_analysis: 'Ընդլայնված Պլանավորում և Վերլուծություն',
  integration: 'Ինտեգրում',
},
  };
  
  export const regions = [
    {
      name: 'Africa',
      countries: [
        { name: 'Algeria', code: 'ar' },
        { name: 'Angola', code: 'pt' },
        { name: 'Benin', code: 'fr' },
        { name: 'Botswana', code: 'en' },
        { name: 'Burkina Faso', code: 'fr' },
        { name: 'Burundi', code: 'rn' },
        { name: 'Cabo Verde', code: 'pt' },
        { name: 'Cameroon', code: 'fr' },
        { name: 'Central African Republic', code: 'fr' },
        { name: 'Chad', code: 'fr' },
        { name: 'Comoros', code: 'ar' },
        { name: 'Congo, Republic of the', code: 'fr' },
        { name: 'Congo, Democratic Republic of the', code: 'fr' },
        { name: "Côte d'Ivoire", code: 'fr' },
        { name: 'Djibouti', code: 'fr' },
        { name: 'Egypt', code: 'ar' },
        { name: 'Equatorial Guinea', code: 'es' },
        { name: 'Eritrea', code: 'ti' },
        { name: 'Eswatini', code: 'ss' },
        { name: 'Ethiopia', code: 'am' },
        { name: 'Gabon', code: 'fr' },
        { name: 'Gambia', code: 'en' },
        { name: 'Ghana', code: 'en' },
        { name: 'Guinea', code: 'fr' },
        { name: 'Guinea-Bissau', code: 'pt' },
        { name: 'Kenya', code: 'sw' },
        { name: 'Lesotho', code: 'st' },
        { name: 'Liberia', code: 'en' },
        { name: 'Libya', code: 'ar' },
        { name: 'Madagascar', code: 'mg' },
        { name: 'Malawi', code: 'en' },
        { name: 'Mali', code: 'fr' },
        { name: 'Mauritania', code: 'ar' },
        { name: 'Mauritius', code: 'en' },
        { name: 'Morocco', code: 'ar' },
        { name: 'Mozambique', code: 'pt' },
        { name: 'Namibia', code: 'en' },
        { name: 'Niger', code: 'fr' },
        { name: 'Nigeria', code: 'en' },
        { name: 'Rwanda', code: 'rw' },
        { name: 'Senegal', code: 'fr' },
        { name: 'Sierra Leone', code: 'en' },
        { name: 'Somalia', code: 'so' },
        { name: 'South Africa', code: 'en' },
        { name: 'South Sudan', code: 'en' },
        { name: 'Sudan', code: 'ar' },
        { name: 'Tanzania', code: 'sw' },
        { name: 'Togo', code: 'fr' },
        { name: 'Tunisia', code: 'ar' },
        { name: 'Uganda', code: 'en' },
        { name: 'Zambia', code: 'en' },
        { name: 'Zimbabwe', code: 'en' },
      ],
    },
    {
      name: 'Asia',
      countries: [
        { name: 'Afghanistan', code: 'ps' },
        { name: 'Armenia', code: 'hy' },
        { name: 'Azerbaijan', code: 'az' },
        { name: 'Bahrain', code: 'ar' },
        { name: 'Bangladesh', code: 'bn' },
        { name: 'Bhutan', code: 'dz' },
        { name: 'Brunei', code: 'ms' },
        { name: 'Cambodia', code: 'km' },
        { name: 'China', code: 'zh' },
        { name: 'Cyprus', code: 'el' },
        { name: 'Georgia', code: 'ka' },
        { name: 'India', code: 'hi' },
        { name: 'Indonesia', code: 'id' },
        { name: 'Iran', code: 'fa' },
        { name: 'Iraq', code: 'ar' },
        { name: 'Israel', code: 'he' },
        { name: 'Japan', code: 'ja' },
        { name: 'Jordan', code: 'ar' },
        { name: 'Kazakhstan', code: 'kk' },
        { name: 'Kuwait', code: 'ar' },
        { name: 'Kyrgyzstan', code: 'ky' },
        { name: 'Laos', code: 'lo' },
        { name: 'Lebanon', code: 'ar' },
        { name: 'Malaysia', code: 'ms' },
        { name: 'Maldives', code: 'dv' },
        { name: 'Mongolia', code: 'mn' },
        { name: 'Myanmar', code: 'my' },
        { name: 'Nepal', code: 'ne' },
        { name: 'North Korea', code: 'ko' },
        { name: 'Oman', code: 'ar' },
        { name: 'Pakistan', code: 'ur' },
        { name: 'Palestine', code: 'ar' },
        { name: 'Philippines', code: 'tl' },
        { name: 'Qatar', code: 'ar' },
        { name: 'Saudi Arabia', code: 'ar' },
        { name: 'Singapore', code: 'en' },
        { name: 'Sri Lanka', code: 'si' },
        { name: 'Syria', code: 'ar' },
        { name: 'Tajikistan', code: 'tg' },
        { name: 'Thailand', code: 'th' },
        { name: 'Timor-Leste', code: 'tet' },
        { name: 'Turkmenistan', code: 'tk' },
        { name: 'United Arab Emirates', code: 'ar' },
        { name: 'Uzbekistan', code: 'uz' },
        { name: 'Vietnam', code: 'vi' },
        { name: 'Yemen', code: 'ar' },
      ],
    },
    {
      name: 'Europe',
      countries: [
        { name: 'Albania', code: 'sq' },
        { name: 'Andorra', code: 'ca' },
        { name: 'Austria', code: 'de' },
        { name: 'Belarus', code: 'be' },
        { name: 'Belgium', code: 'fr' },
        { name: 'Bosnia and Herzegovina', code: 'bs' },
        { name: 'Bulgaria', code: 'bg' },
        { name: 'Croatia', code: 'hr' },
        { name: 'Cyprus', code: 'el' },
        { name: 'Czech Republic', code: 'cs' },
        { name: 'Denmark', code: 'da' },
        { name: 'Estonia', code: 'et' },
        { name: 'Finland', code: 'fi' },
        { name: 'France', code: 'fr' },
        { name: 'Germany', code: 'de' },
        { name: 'Greece', code: 'el' },
        { name: 'Hungary', code: 'hu' },
        { name: 'Iceland', code: 'is' },
        { name: 'Ireland', code: 'en' },
        { name: 'Italy', code: 'it' },
        { name: 'Latvia', code: 'lv' },
        { name: 'Liechtenstein', code: 'de' },
        { name: 'Lithuania', code: 'lt' },
        { name: 'Luxembourg', code: 'fr' },
        { name: 'Malta', code: 'mt' },
        { name: 'Moldova', code: 'ro' },
        { name: 'Monaco', code: 'fr' },
        { name: 'Montenegro', code: 'sr' },
        { name: 'Netherlands', code: 'nl' },
        { name: 'North Macedonia', code: 'mk' },
        { name: 'Norway', code: 'no' },
        { name: 'Poland', code: 'pl' },
        { name: 'Portugal', code: 'pt' },
        { name: 'Romania', code: 'ro' },
        { name: 'Russia', code: 'ru' },
        { name: 'San Marino', code: 'it' },
        { name: 'Serbia', code: 'sr' },
        { name: 'Slovakia', code: 'sk' },
        { name: 'Slovenia', code: 'si' },
        { name: 'Spain', code: 'es' },
        { name: 'Sweden', code: 'sv' },
        { name: 'Switzerland', code: 'de' },
        { name: 'Ukraine', code: 'uk' },
        { name: 'United Kingdom', code: 'en' },
        { name: 'Vatican City', code: 'it' },
      ],
    },
    {
      name: 'North America',
      countries: [
        { name: 'Antigua and Barbuda', code: 'en' },
        { name: 'Bahamas', code: 'en' },
        { name: 'Barbados', code: 'en' },
        { name: 'Belize', code: 'en' },
        { name: 'Canada', code: 'en' },
        { name: 'Costa Rica', code: 'es' },
        { name: 'Cuba', code: 'es' },
        { name: 'Dominica', code: 'en' },
        { name: 'Dominican Republic', code: 'es' },
        { name: 'El Salvador', code: 'es' },
        { name: 'Grenada', code: 'en' },
        { name: 'Guatemala', code: 'es' },
        { name: 'Haiti', code: 'fr' },
        { name: 'Honduras', code: 'es' },
        { name: 'Jamaica', code: 'en' },
        { name: 'Mexico', code: 'es' },
        { name: 'Nicaragua', code: 'es' },
        { name: 'Panama', code: 'es' },
        { name: 'Saint Kitts and Nevis', code: 'en' },
        { name: 'Saint Lucia', code: 'en' },
        { name: 'Saint Vincent and the Grenadines', code: 'en' },
        { name: 'United States', code: 'en' },
      ],
    },
    {
      name: 'Oceania',
      countries: [
        { name: 'Australia', code: 'en' },
        { name: 'Fiji', code: 'en' },
        { name: 'French Polynesia', code: 'fr' },
        { name: 'Kiribati', code: 'en' },
        { name: 'Marshall Islands', code: 'mh' },
        { name: 'Micronesia', code: 'en' },
        { name: 'Nauru', code: 'na' },
        { name: 'New Caledonia', code: 'fr' },
        { name: 'New Zealand', code: 'en' },
        { name: 'Papua New Guinea', code: 'en' },
        { name: 'Samoa', code: 'sm' },
        { name: 'Solomon Islands', code: 'en' },
        { name: 'Tonga', code: 'to' },
        { name: 'Tuvalu', code: 'tvl' },
        { name: 'Vanuatu', code: 'bi' },
      ],
    },
    {
      name: 'South America',
      countries: [
        { name: 'Argentina', code: 'es' },
        { name: 'Bolivia', code: 'es' },
        { name: 'Brazil', code: 'pt' },
        { name: 'Chile', code: 'es' },
        { name: 'Colombia', code: 'es' },
        { name: 'Ecuador', code: 'es' },
        { name: 'French Guiana', code: 'fr' },
        { name: 'Guyana', code: 'en' },
        { name: 'Paraguay', code: 'es' },
        { name: 'Peru', code: 'es' },
        { name: 'Suriname', code: 'nl' },
        { name: 'Uruguay', code: 'es' },
        { name: 'Venezuela', code: 'es' },
      ],
    },
  ];
  
  

  // Update this object with correct ISO country codes
  export const countryToISOCode = {
    'Afghanistan': 'af',
    'Albania': 'al',
    'Algeria': 'dz',
    'Andorra': 'ad',
    'Angola': 'ao',
    'Antigua and Barbuda': 'ag',
    'Argentina': 'ar',
    'Armenia': 'am',
    'Australia': 'au',
    'Austria': 'at',
    'Azerbaijan': 'az',
    'Bahamas': 'bs',
    'Bahrain': 'bh',
    'Bangladesh': 'bd',
    'Barbados': 'bb',
    'Belarus': 'by',
    'Belgium': 'be',
    'Belize': 'bz',
    'Benin': 'bj',
    'Bhutan': 'bt',
    'Bolivia': 'bo',
    'Bosnia and Herzegovina': 'ba',
    'Botswana': 'bw',
    'Brazil': 'br',
    'Brunei': 'bn',
    'Bulgaria': 'bg',
    'Burkina Faso': 'bf',
    'Burundi': 'bi',
    'Cabo Verde': 'cv',
    'Cambodia': 'kh',
    'Cameroon': 'cm',
    'Canada': 'ca',
    'Central African Republic': 'cf',
    'Chad': 'td',
    'Chile': 'cl',
    'China': 'cn',
    'Colombia': 'co',
    'Comoros': 'km',
    'Congo, Democratic Republic of the': 'cd',
    'Congo, Republic of the': 'cg',
    'Costa Rica': 'cr',
    "Côte d'Ivoire": 'ci',
    'Croatia': 'hr',
    'Cuba': 'cu',
    'Cyprus': 'cy',
    'Czech Republic': 'cz',
    'Denmark': 'dk',
    'Djibouti': 'dj',
    'Dominica': 'dm',
    'Dominican Republic': 'do',
    'Ecuador': 'ec',
    'Egypt': 'eg',
    'El Salvador': 'sv',
    'Equatorial Guinea': 'gq',
    'Eritrea': 'er',
    'Estonia': 'ee',
    'Eswatini': 'sz',
    'Ethiopia': 'et',
    'Fiji': 'fj',
    'Finland': 'fi',
    'France': 'fr',
    'French Guiana': 'gf',
    'French Polynesia': 'pf',
    'Gabon': 'ga',
    'Gambia': 'gm',
    'Georgia': 'ge',
    'Germany': 'de',
    'Ghana': 'gh',
    'Greece': 'gr',
    'Grenada': 'gd',
    'Guatemala': 'gt',
    'Guinea': 'gn',
    'Guinea-Bissau': 'gw',
    'Guyana': 'gy',
    'Haiti': 'ht',
    'Honduras': 'hn',
    'Hungary': 'hu',
    'Iceland': 'is',
    'India': 'in',
    'Indonesia': 'id',
    'Iran': 'ir',
    'Iraq': 'iq',
    'Ireland': 'ie',
    'Israel': 'il',
    'Italy': 'it',
    'Jamaica': 'jm',
    'Japan': 'jp',
    'Jordan': 'jo',
    'Kazakhstan': 'kz',
    'Kenya': 'ke',
    'Kiribati': 'ki',
    'Korea, North': 'kp',
    'Korea, South': 'kr',
    'Kuwait': 'kw',
    'Kyrgyzstan': 'kg',
    'Laos': 'la',
    'Latvia': 'lv',
    'Lebanon': 'lb',
    'Lesotho': 'ls',
    'Liberia': 'lr',
    'Libya': 'ly',
    'Liechtenstein': 'li',
    'Lithuania': 'lt',
    'Luxembourg': 'lu',
    'Madagascar': 'mg',
    'Malawi': 'mw',
    'Malaysia': 'my',
    'Maldives': 'mv',
    'Mali': 'ml',
    'Malta': 'mt',
    'Marshall Islands': 'mh',
    'Mauritania': 'mr',
    'Mauritius': 'mu',
    'Mexico': 'mx',
    'Micronesia': 'fm',
    'Moldova': 'md',
    'Monaco': 'mc',
    'Mongolia': 'mn',
    'Montenegro': 'me',
    'Morocco': 'ma',
    'Mozambique': 'mz',
    'Myanmar': 'mm',
    'Namibia': 'na',
    'Nauru': 'nr',
    'Nepal': 'np',
    'Netherlands': 'nl',
    'New Caledonia' : 'nc',
    'New Zealand': 'nz',
    'Nicaragua': 'ni',
    'Niger': 'ne',
    'Nigeria': 'ng',
    'North Korea': 'kp',
    'North Macedonia': 'mk',
    'Norway': 'no',
    'Oman': 'om',
    'Pakistan': 'pk',
    'Palau': 'pw',
    'Palestine': 'ps',
    'Panama': 'pa',
    'Papua New Guinea': 'pg',
    'Paraguay': 'py',
    'Peru': 'pe',
    'Philippines': 'ph',
    'Poland': 'pl',
    'Portugal': 'pt',
    'Qatar': 'qa',
    'Romania': 'ro',
    'Russia': 'ru',
    'Rwanda': 'rw',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Vincent and the Grenadines': 'vc',
    'Samoa': 'ws',
    'San Marino': 'sm',
    'Sao Tome and Principe': 'st',
    'Saudi Arabia': 'sa',
    'Senegal': 'sn',
    'Serbia': 'rs',
    'Seychelles': 'sc',
    'Sierra Leone': 'sl',
    'Singapore': 'sg',
    'Slovakia': 'sk',
    'Slovenia': 'si',
    'Solomon Islands': 'sb',
    'Somalia': 'so',
    'South Africa': 'za',
    'South Sudan': 'ss',
    'Spain': 'es',
    'Sri Lanka': 'lk',
    'Sudan': 'sd',
    'Suriname': 'sr',
    'Sweden': 'se',
    'Switzerland': 'ch',
    'Syria': 'sy',
    'Taiwan': 'tw',
    'Tajikistan': 'tj',
    'Tanzania': 'tz',
    'Thailand': 'th',
    'Timor-Leste': 'tl',
    'Togo': 'tg',
    'Tonga': 'to',
    'Trinidad and Tobago': 'tt',
    'Tunisia': 'tn',
    'Turkey': 'tr',
    'Turkmenistan': 'tm',
    'Tuvalu': 'tv',
    'Uganda': 'ug',
    'Ukraine': 'ua',
    'United Arab Emirates': 'ae',
    'United Kingdom': 'gb',
    'United States': 'us',
    'Uruguay': 'uy',
    'Uzbekistan': 'uz',
    'Vanuatu': 'vu',
    'Vatican City': 'va',
    'Venezuela': 've',
    'Vietnam': 'vn',
    'Yemen': 'ye',
    'Zambia': 'zm',
    'Zimbabwe': 'zw',
};

  
  