import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('Password should be at least 8 characters');
      return;
    }
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('inventory/dashboard');
    } catch (error) {
      setError('Error logging in. Please try again.');
      setTimeout(() => {
        if (error.code === 'auth/user-not-found') {
          setError('Email not found');
        } else if (error.code === 'auth/wrong-password') {
          setError('Incorrect password');
        }
      }, 100);
    }
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2 className="h2">Sign in</h2>
        <hr />

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              className='auth-input'
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <div className="password-container">
              <input
                className='auth-input'
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={handlePasswordBlur}
                required
              />
              <button
                type="button"
                className="password-toggle-text"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                <p>{showPassword ? "Hide" : "Show"}</p>
              </button>
            </div>
            {passwordTouched && password.length < 8 && (
              <p className="password-hint invalid">
                Password should be at least 8 characters
              </p>
            )}
          </div>
          <button type="submit" className="auth-button">Login</button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default Login;