import React, { useState, useCallback, useEffect } from 'react';
import { Search, X, ChevronLeft, ChevronRight } from 'lucide-react';

const GoogleCustomSearch = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  const searchId = '8798c64794b2b424d';
  const apiKey = 'AIzaSyDW_9Qot8WuD7GBdZLZsM5K6fnd6TKQfSg';
  const resultsPerPage = 10;

  const fetchSuggestions = useCallback(async (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://suggestqueries.google.com/complete/search?client=youtube&ds=yt&q=${encodeURIComponent(input)}`
      );
      const data = await response.json();
      setSuggestions(data[1]);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSuggestions(query);
    }, 300);

    return () => clearTimeout(timer);
  }, [query, fetchSuggestions]);

  const handleSearch = useCallback(async (e, page = 1) => {
    e?.preventDefault();
    if (!query) return;

    setIsLoading(true);
    setShowSuggestions(false);
    try {
      const startIndex = (page - 1) * resultsPerPage + 1;
      const response = await fetch(
        `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${searchId}&q=${encodeURIComponent(query)}&start=${startIndex}`
      );
      const data = await response.json();
      setResults(data.items || []);
      setTotalResults(data.searchInformation.totalResults);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setResults([]);
    }
    setIsLoading(false);
  }, [query, apiKey, searchId]);

  const handleClear = () => {
    setQuery('');
    setResults([]);
    setSuggestions([]);
    setCurrentPage(1);
    setTotalResults(0);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    handleSearch({ preventDefault: () => {} });
  };

  const getFaviconUrl = (url) => {
    try {
      const domain = new URL(url).hostname;
      return `https://www.google.com/s2/favicons?domain=${domain}`;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const maxPageButtons = 10;
  const pageNumbers = [];
  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  if (endPage - startPage + 1 < maxPageButtons) {
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="google-custom-search" style={{ maxWidth: '800px', margin: '0 auto' }}>
      <form onSubmit={handleSearch} className="search-form">
        <div className="search-input-wrapper">
          <Search className="search-icon" size={20} />
          <input
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setShowSuggestions(true);
            }}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
            placeholder="Search..."
            className="search-input-market-research"
          />
          {query && (
            <button type="button" onClick={handleClear} className="clear-button">
              <X size={20} />
            </button>
          )}
        </div>
        <button type="submit" className="search-button" disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </form>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="search-suggestions">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <Search size={16} />
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      <div className="search-results">
        {results.map((result, index) => (
          <div key={index} className="search-result-item">
            <div className="result-header">
              <img 
                src={getFaviconUrl(result.link)} 
                alt="Site favicon" 
                className="result-favicon"
              />
              <a href={result.link} target="_blank" rel="noopener noreferrer" className="result-title">
                {result.title}
              </a>
            </div>
            <p className="result-snippet">{result.snippet}</p>
            <a href={result.link} target="_blank" rel="noopener noreferrer" className="result-link">
              {result.displayLink}
            </a>
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          <button
            onClick={(e) => handleSearch(e, currentPage - 1)}
            disabled={currentPage === 1}
            className="page-button"
          >
            <ChevronLeft size={20} />
          </button>
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={(e) => handleSearch(e, number)}
              className={`page-button ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </button>
          ))}
          <button
            onClick={(e) => handleSearch(e, currentPage + 1)}
            disabled={currentPage === totalPages}
            className="page-button"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default GoogleCustomSearch;