import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import './ItemAdd.css';
import ConfirmationDialog from './ConfirmationDialog';

const ItemAdd = () => {
 const [items, setItems] = useState([]);
 const [filter, setFilter] = useState('All');
 const [newItem, setNewItem] = useState({
  name: '',
  brand: '',
  category: '',
  unitOfMeasureForWeight: '',
  unitOfMeasureForVolume: '',
  volume: '',
  weight: '',
  quantity: '',
  expiryDateFrom: '',
  expiryDateTo: '',
  noExpiryDate: false,
  sku: '',
  barcode: '',
  price: '',
  costPrice: '',
  description: '',
  lowStockThreshold: '',
  selectedStores: '',
  filter:'',
});
 const [editingId, setEditingId] = useState(null);
 const [brandName, setBrandName] = useState('');
 const [stores, setStores] = useState([]);
 const [selectedStore, setSelectedStore] = useState('');
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState('');
 const [showConfirmation, setShowConfirmation] = useState(false);
 const [confirmAction, setConfirmAction] = useState(null);
 const [modifiedBrandName, setModifiedBrandName] = useState('');

 const auth = getAuth();
 const db = getFirestore();

 const categories = [
   'Groceries', 'Beverages', 'Household Products', 'Personal Care Items', 
   'Snacks', 'Frozen Foods', 'Health and Wellness Products', 'Electronics', 
   'Pet Supplies', 'Office Supplies', 'Raw Materials'
 ];

 const unitsOfMeasureForWeight = [
   'Kilogram (kg)', 
   'Gram (g)', 
   'Ounce (oz)',
   'Pound (lb)', 
   'N/A'
 ];

 const unitsOfMeasureForVolume = [
  'Liter (L)', 
  'Milliliter (mL)', 
  'Fluid Ounce (fl oz)', 
  'Cubic Meter (m³)', 
  'N/A'
];

 useEffect(() => {
   fetchBrandNameAndStores();
 }, []);

 useEffect(() => {
   if (brandName && selectedStore) {
     fetchItems();
   }
 }, [brandName, selectedStore]);

 const fetchBrandNameAndStores = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const storeDoc = await getDocs(query(collection(db, 'stores'), where('userId', '==', user.uid)));
      if (!storeDoc.empty) {
        const storeData = storeDoc.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);
        
        const networkStoresSnapshot = await getDocs(collection(db, `stores/${storeData.modifiedBrandName}/networkStores`));
        const storeAddresses = networkStoresSnapshot.docs.map(doc => doc.id);
        setStores(storeAddresses);
      }
    }
  } catch (err) {
    setError('Failed to fetch brand name and stores.');
  } finally {
    setLoading(false);
  }
};


const fetchItems = async () => {
  if (modifiedBrandName && selectedStore) {
    try {
      const q = query(collection(db, `stores/${modifiedBrandName}/networkStores/${selectedStore}/items`));
       const querySnapshot = await getDocs(q);
       const fetchedItems = querySnapshot.docs.map(doc => ({ 
         id: doc.id, 
         ...doc.data(),
         status: getItemStatus(doc.data())
       }));
       setItems(fetchedItems);
     } catch (err) {
       setError('Failed to fetch items.');
     }
   }
 };

 const getItemStatus = (item) => {
   const today = new Date();
   const expirationDate = new Date(item.expiryDateTo);
   
   if (item.quantity === 0) {
     return 'Not Available';
   } else if (expirationDate < today && !item.noExpiryDate) {
     return 'Expired';
   } else if (item.quantity < item.lowStockThreshold) {
     return 'Low Stock';
   } else {
     return 'Available';
   }
 };

 const handleConfirm = async () => {
  setShowConfirmation(false);
  if (confirmAction) {
    await confirmAction();
  }
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setConfirmAction(() => async () => {
    if (modifiedBrandName && selectedStore) {
      const itemData = {
        ...newItem,
        quantity: Number(newItem.quantity),
        price: Number(newItem.price),
        costPrice: Number(newItem.costPrice),
        lowStockThreshold: Number(newItem.lowStockThreshold),
        volume: newItem.volume || 'N/A',
        weight: newItem.weight || 'N/A',
        sku: newItem.sku || 'N/A',
        barcode: newItem.barcode || 'N/A',
        price: newItem.price || 'N/A',
        costPrice:newItem.costPrice || 'N/A',
        description: newItem.description || 'N/A',
        lastUpdated: new Date(),
        dateAdded: editingId ? newItem.dateAdded : new Date()
      };

      try {
        let itemRef = editingId 
          ? doc(db, `stores/${modifiedBrandName}/networkStores/${selectedStore}/items`, editingId)
          : collection(db, `stores/${modifiedBrandName}/networkStores/${selectedStore}/items`);

        if (editingId) {
          await updateDoc(itemRef, itemData);
          await addDoc(collection(db, `stores/${modifiedBrandName}/reports`), {
            action: 'Edited',
            itemName: itemData.name,
            brand: itemData.brand,
            quantity: itemData.quantity,
            store: selectedStore,
            timestamp: new Date()
          });
        } else {
          const docRef = await addDoc(itemRef, itemData);
          await addDoc(collection(db, `stores/${modifiedBrandName}/reports`), {
            action: 'Added',
            itemName: itemData.name,
            brand: itemData.brand,
            quantity: itemData.quantity,
            store: selectedStore,
            timestamp: new Date()
          });
        }
        
        // Check and send notifications
        await checkAndSendNotifications(itemData, selectedStore);

        resetForm();
        fetchItems();
      } catch (err) {
        setError('Failed to save item.');
      }
    }
  });
  setShowConfirmation(true);
};

const checkAndSendNotifications = async (item, store) => {
  const today = new Date();
  const notifications = [];

  if (item.quantity === 0) {
    notifications.push({
      title: 'Not Available',
      store: store,
      item: item.name,
      quantity: item.quantity,
      status: 'Not Available',
      date: today,
      statusPro: ''
    });
  }
  
  if (item.quantity < item.lowStockThreshold && item.quantity > 0) {
    notifications.push({
      title: 'Low Stock Threshold',
      store: store,
      item: item.name,
      quantity: item.quantity,
      status: 'Low Stock Threshold',
      date: today,
      statusPro: ''
    });
  }

  if (!item.noExpiryDate && new Date(item.expiryDateTo) < today) {
    notifications.push({
      title: 'Expired Date',
      store: store,
      item: item.name,
      quantity: item.quantity,
      status: 'Expired Date',
      date: today,
      statusPro: ''
    });
  }

  for (const notification of notifications) {
    try {
      await addDoc(collection(db, `stores/${modifiedBrandName}/notifications`), notification);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  }
};



const resetForm = () => {
  setNewItem({
    name: '',
    brand: '',
    category: '',
    unitOfMeasureForWeight: '',
    unitOfMeasureForVolume: '',
    volume: '',
    weight: '',
    quantity: '',
    expiryDateFrom: '',
    expiryDateTo: '',
    noExpiryDate: false,
    sku: '',
    barcode: '',
    price: '',
    costPrice: '',
    description: '',
    lowStockThreshold: '',
  });
  setEditingId(null);
};

 const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  setNewItem(prevItem => ({ 
    ...prevItem, 
    [name]: type === 'checkbox' ? checked : value 
}));
};

const isFormValid = () => {
  return selectedStore && 
         newItem.name && 
         newItem.brand && 
         newItem.category && 
         newItem.unitOfMeasureForWeight && 
         newItem.unitOfMeasureForVolume &&
         newItem.quantity && 
         newItem.price && 
         newItem.costPrice &&
         newItem.lowStockThreshold &&
(newItem.noExpiryDate || (newItem.expiryDateFrom && newItem.expiryDateTo));
};

const filteredItems = items.filter(item => {
  if (filter === 'All') return true;
  return item.status === filter;
}).sort((a, b) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  if (nameA < nameB) {
  return -1;
  }
  if (nameA > nameB) {
  return 1;
  }
  return 0;
});



 return (
  <div className={`itemadd-container ${showConfirmation ? 'confirmation-active' : ''}`}>
    <h2 className="itemadd-title">{editingId ? 'Edit Item' : 'Add New Item'}</h2>
    {error && <p className="itemadd-error">{error}</p>}
    <form className="itemadd-form" onSubmit={handleSubmit}>
      <div className="itemadd-form-group">
        <select
          value={selectedStore}
          onChange={(e) => setSelectedStore(e.target.value)}
          required
        >
          <option value="">Select Store</option>
          {stores.map(store => (
            <option key={store} value={store}>{store}</option>
          ))}
        </select>
      </div>
      <div className="itemadd-form-group">
        <select
          name="category"
          value={newItem.category}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Category</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <div className="itemadd-form-group">
        <input
          className="itemadd-form-group-input-i"
          type="text"
          name="name"
          value={newItem.name}
          onChange={handleInputChange}
          placeholder="Name of the product"
          required
        />
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="text"
          name="brand"
          value={newItem.brand}
          onChange={handleInputChange}
          placeholder="Name of the Brand"
          required
        />
      </div>
      <div className="itemadd-form-group">
        <select
          name="unitOfMeasureForVolume"
          value={newItem.unitOfMeasureForVolume}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Volume Unit</option>
          {unitsOfMeasureForVolume.map(unitV => (
            <option key={unitV} value={unitV}>{unitV}</option>
          ))}
        </select>
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="text"
          name="volume"
          value={newItem.volume}
          onChange={handleInputChange}
          placeholder={
            newItem.unitOfMeasureForVolume === "N/A"
              ? "Volume not applicable"
              : `Volume of one unit in ${newItem.unitOfMeasureForVolume}`
            }
          disabled={newItem.unitOfMeasureForVolume === "N/A"}
           />
      
      </div>
      <div className="itemadd-form-group">
        <select
          name="unitOfMeasureForWeight"
          value={newItem.unitOfMeasureForWeight}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Weight Unit</option>
          {unitsOfMeasureForWeight.map(unitW => (
            <option key={unitW} value={unitW}>{unitW}</option>
          ))}
        </select>
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="text"
          name="weight"
          value={newItem.weight}
          onChange={handleInputChange}
          placeholder={
            newItem.unitOfMeasureForWeight === "N/A"
              ? "Weight not applicable"
              : `Weight of one unit in ${newItem.unitOfMeasureForWeight}`
          }
          disabled={newItem.unitOfMeasureForWeight === "N/A"}
          />
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="number"
          name="quantity"
          value={newItem.quantity}
          onChange={handleInputChange}
          placeholder="Quantity"
          required
        />
      </div>
      <div className="itemadd-form-group">
        <input
          className="itemadd-form-group-input-i"
          type="number"
          name="lowStockThreshold"
          value={newItem.lowStockThreshold}
          onChange={handleInputChange}
          placeholder="Low Stock Threshold"
          required
        />
      </div>
      <div className="itemadd-form-group date-inputs">
        <input
          className="date-inputs-i"
          type="date"
          name="expiryDateFrom"
          value={newItem.expiryDateFrom}
          onChange={handleInputChange}
          disabled={newItem.noExpiryDate}
          required={!newItem.noExpiryDate}
       
        />
        <input
          type="date"
          className="date-inputs-i"
          name="expiryDateTo"
          value={newItem.expiryDateTo}
          onChange={handleInputChange}
          disabled={newItem.noExpiryDate}
          required={!newItem.noExpiryDate}
        />
        <label>
        <div className='checkbox-container' >
          <input
            type="checkbox"
            name="noExpiryDate"
            checked={newItem.noExpiryDate}
            onChange={handleInputChange}
          />
          N/A

          </div>
        </label>
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="text"
          name="sku"
          value={newItem.sku}
          onChange={handleInputChange}
          placeholder="SKU (Stock Keeping Unit)"
        />
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="text"
          name="barcode"
          value={newItem.barcode}
          onChange={handleInputChange}
          placeholder="Barcode/UPC"
        />
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="number"
          name="price"
          value={newItem.price}
          onChange={handleInputChange}
          placeholder="Selling Price per unit"
          required
        />
      </div>
      <div className="itemadd-form-group">
        <input
        className="itemadd-form-group-input-i"
          type="number"
          name="costPrice"
          value={newItem.costPrice}
          onChange={handleInputChange}
          placeholder="Cost Price per unit"
          required
        />
      </div>
      
      <div className="itemadd-form-group">
        <textarea
          name="description"
          value={newItem.description}
          onChange={handleInputChange}
          placeholder="Description (optional)"
        />
      </div>
      
      <div className="itemadd-form-group">
        <button type="submit" className="itemadd-submit-btn" disabled={!isFormValid()}>
          {editingId ? 'Update Item' : 'Add Item'}
        </button>
      </div>
    </form>

    <div className="item-filter">
      <select value={filter} onChange={(e) => setFilter(e.target.value)}>
        <option value="All">All Items</option>
        <option value="Available">Available Items</option>
        <option value="Low Stock">Low Stock Items</option>
        <option value="Not Available">Not Available Items</option>
        <option value="Expired">Expired Items</option>
      </select>
    </div>

    <div className="itemadd-list">
  <h2>Inventory Items</h2>
  {loading ? (
    <p>Loading...</p>
  ) : (
    <div>
      <ul>
        {filteredItems.map(item => (
        <li key={item.id} className="itemadd-list-item">
        {item.name} Quantity: {item.quantity} - Category: {item.category} - Brand: {item.brand} - Status: {item.status}
        <button className="itemadd-btn-edit" onClick={() => {
      setNewItem(item);
      setEditingId(item.id);
      setConfirmAction(() => () => {
      setNewItem(item);
      setEditingId(item.id);
    });
  }}>Edit</button>
        <button className="itemadd-btn-delete" onClick={() => {
      setConfirmAction(() => async () => {
      try {
      await deleteDoc(doc(db, `stores/${brandName}/networkStores/${selectedStore}/items`, item.id));
      await addDoc(collection(db, `stores/${brandName}/reports`), {
      action: 'Deleted',
      itemName: item.name,
      brand: item.brand,
      quantity: item.quantity,
      store: selectedStore,
      timestamp: new Date()
    });
      fetchItems();
    } catch (err) {
    setError('Failed to delete item.');
    }
  });
    setShowConfirmation(true);
  }}>Delete</button>
      </li>
    ))}
    </ul>
    </div>
  )}
</div>
     
       
{showConfirmation && (
  <ConfirmationDialog
  message="Are you sure you want to proceed?"
  onConfirm={handleConfirm}
  onCancel={() => setShowConfirmation(false)}
  confirmText="Confirm"
  cancelText="Cancel"
/>
)}
    </div>
  );
};

export default ItemAdd;