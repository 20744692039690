import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, setDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [brandName, setBrandName] = useState('');
  const [status, setStatus] = useState('');
  const [numberOfStores, setNumberOfStores] = useState(1);
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  const navigate = useNavigate();

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 10);
  };

  useEffect(() => {
    if (brandName) {
      const uniqueId = generateUniqueId();
      setModifiedBrandName(`${brandName}${uniqueId}`);
    }
  }, [brandName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('Password should be at least 8 characters');
      setSaveStatus('error');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (status === 'Store Network' || status === 'Store') {
        const storeNetworkRef = doc(db, "stores", modifiedBrandName);
        await setDoc(storeNetworkRef, {
          userId: user.uid,
          email: email,
          brandName: brandName,
          modifiedBrandName: modifiedBrandName,
          xValue: modifiedBrandName.slice(-8),
          status: status,
          numberOfStores: numberOfStores,
          createdAt: new Date()
        });

        for (let store of stores) {
          const storeRef = doc(collection(storeNetworkRef, "networkStores"), store.address);
          await setDoc(storeRef, store);
        }
      }
      const welcomeNotificationRef = doc(db, `stores/${modifiedBrandName}/notifications`, 'welcome');
      await setDoc(welcomeNotificationRef, {
        title: 'Welcome',
        message: `Hi ${brandName}, Welcome to DAXTOP.com! Your registration is complete. We're excited to have you with us.`,
        date: new Date(),
        statusPro: ''
      });

      setSaveStatus('success');
      setTimeout(() => navigate('inventory/dashboard'), 2000);
    } catch (error) {
      setSaveStatus('error');
      if (error.code === 'auth/email-already-in-use') {
        setError('Email already in use');
      } else {
        setError('Error saving changes. Please try again.');
      }
    }
  };

  const handleStoreInfoChange = (index, field, value) => {
    const updatedStores = [...stores];
    if (!updatedStores[index]) {
      updatedStores[index] = {};
    }
    updatedStores[index][field] = value;
    setStores(updatedStores);
  };

  const handleNumberOfStoresChange = (e) => {
    const value = e.target.value;
    if (value === '' || parseInt(value) < 1) {
      setNumberOfStores(1);
    } else {
      setNumberOfStores(parseInt(value));
    }
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2 className="h2">Sign up</h2>
        <hr />

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              className='auth-input'
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <div className="password-container">
              <input
              className='auth-input'
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={handlePasswordBlur}
                required
              />
              <button
                type="button"
                className="password-toggle-text"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                <p>{showPassword ? "Hide" : "Show"}</p>
              </button>
            </div>
            {passwordTouched && password.length < 8 && (
              <p className="password-hint invalid">
                Password should be at least 8 characters
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="brandName">Brand Name:</label>
            <input
              className='auth-input'
              type="text"
              id="brandName"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="status">Status:</label>
            <select
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <option value="">Select Status</option>
              <option value="Store Network">Store</option>
            </select>
          </div>
          {status === 'Store Network' && (
            <div className="form-group">
              <label htmlFor="numberOfStores">Number of Stores:</label>
              <input
              className='auth-input'
                type="number"
                id="numberOfStores"
                value={numberOfStores}
                onChange={handleNumberOfStoresChange}
                min="1"
                max="50"
                required
              />
            </div>
          )}
          {(status === 'Store' ? [0] : [...Array(numberOfStores).keys()]).map((_, index) => (
            <div key={index} className="store-info-auth">
              <h3>{status === 'Store' ? 'Store Information' : `Store ${index + 1} Information`}</h3>
              <div className="form-group">
                <label htmlFor={`country-${index}`}>Country:</label>
                <input
              className='auth-input'
                  type="text"
                  id={`country-${index}`}
                  value={stores[index]?.country || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'country', e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`state-${index}`}>State:</label>
                <input
              className='auth-input'
                  type="text"
                  id={`state-${index}`}
                  value={stores[index]?.state || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'state', e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`city-${index}`}>City:</label>
                <input
              className='auth-input'
                  type="text"
                  id={`city-${index}`}
                  value={stores[index]?.city || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'city', e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`address-${index}`}>Address:</label>
                <input
              className='auth-input'
                  type="text"
                  id={`address-${index}`}
                  value={stores[index]?.address || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'address', e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`size-${index}`}>Size (sq m):</label>
                <input
              className='auth-input'
                  type="number"
                  id={`size-${index}`}
                  value={stores[index]?.size || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'size', e.target.value)}
                  min='1'
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`employees-${index}`}>Number of Employees:</label>
                <input
              className='auth-input'
                  type="number"
                  id={`employees-${index}`}
                  value={stores[index]?.employees || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'employees', e.target.value)}
                  min='1'
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`phone-${index}`}>Contact Phone:</label>
                <input
              className='auth-input'
                  type="tel"
                  id={`phone-${index}`}
                  value={stores[index]?.phone || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'phone', e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`contactEmail-${index}`}>Contact Email (optional):</label>
                <input
              className='auth-input'
                  type="email"
                  id={`contactEmail-${index}`}
                  value={stores[index]?.contactEmail || ''}
                  onChange={(e) => handleStoreInfoChange(index, 'contactEmail', e.target.value)}
                />
              </div>
            </div>
          ))}
          <button type="submit" className="auth-button">Register</button>
          {saveStatus && (
            <div className={`save-status ${saveStatus}`}>
              {saveStatus === 'saving' && 'Saving changes...'}
              {saveStatus === 'success' && 'Changes saved successfully!'}
              {saveStatus === 'error' && error}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;