import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area } from 'recharts';
import './Dashboard.css';
import LoadingAnimation from './LoadingPages/LoadingAnimation'

const Dashboard = () => {
  const [items, setItems] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    fetchBrandNameAndStores();
  }, []);

  useEffect(() => {
    if (brandName && stores.length > 0) {
      fetchAllStoresItems();
      fetchInventoryData();
    }
  }, [brandName, stores]);

  const fetchBrandNameAndStores = async () => {
    const user = auth.currentUser;
    if (user) {
      const storeDoc = await getDocs(query(collection(db, 'stores'), where('userId', '==', user.uid)));
      if (!storeDoc.empty) {
        const storeData = storeDoc.docs[0].data();
        setBrandName(storeData.brandName);
        setModifiedBrandName(storeData.modifiedBrandName);
        
        const networkStoresSnapshot = await getDocs(collection(db, `stores/${storeData.modifiedBrandName}/networkStores`));
        const storeAddresses = networkStoresSnapshot.docs.map(doc => doc.id);
        setStores(storeAddresses);
      }
    }
  };

  const fetchAllStoresItems = async () => {
    let allItems = [];
    
    for (let store of stores) {
      const q = query(collection(db, `stores/${modifiedBrandName}/networkStores/${store}/items`));
      const querySnapshot = await getDocs(q);
      const storeItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), store }));
      allItems = [...allItems, ...storeItems];
    }
    
    setItems(allItems);
  };

  const fetchInventoryData = async () => {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const reportsRef = collection(db, `stores/${modifiedBrandName}/reports`);
    const q = query(reportsRef, where('timestamp', '>=', threeMonthsAgo));
    const querySnapshot = await getDocs(q);

    const data = querySnapshot.docs.map(doc => doc.data());

    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.timestamp.toDate()).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = { date, added: 0, removed: 0 };
      }
      if (item.action === 'Added') {
        acc[date].added += item.quantity;
      } else if (item.action === 'Taken Out') {
        acc[date].removed += item.quantity;
      }
      return acc;
    }, {});

    const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));
    setGraphData(sortedData);
  };

  if (loading) {
    return <LoadingAnimation onLoadComplete={() => setLoading(false)} />;
  }

  const totalItems = items.length;
  const lowStockItems = items.filter(item => item.quantity < item.lowStockThreshold).length;
  const expiredItems = items.filter(item => {
    const expirationDate = item.expiryDateTo ? new Date(item.expiryDateTo) : null;
    return expirationDate && expirationDate < new Date() && !item.noExpiryDate;
  }).length;
  const totalValue = items.reduce((sum, item) => sum + (item.quantity * (item.price || 0)), 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Date: ${new Date(label).toLocaleDateString()}`}</p>
          <p className="info added">{`Items Added: ${payload[0].value}`}</p>
          <p className="info removed">{`Items Removed: ${payload[1].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const AnalyticsContent = () => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={graphData}>
        <defs>
          <linearGradient id="colorAdded" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.1}/>
            <stop offset="95%" stopColor="#4CAF50" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorRemoved" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#6936f4" stopOpacity={0.1}/>
            <stop offset="95%" stopColor="#6936f4" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
        <XAxis 
          dataKey="date" 
          stroke="#333"
          tick={{ fill: '#333', fontSize: 12 }}
          tickFormatter={(tick) => new Date(tick).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
        />
        <YAxis 
          stroke="#333"
          tick={{ fill: '#333', fontSize: 12 }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend 
          verticalAlign="top" 
          height={36}
          iconType="circle"
        />
        <Area 
          type="monotone" 
          dataKey="added" 
          stroke="#4CAF50" 
          fillOpacity={1}
          fill="url(#colorAdded)" 
        />
        <Area 
          type="monotone" 
          dataKey="removed" 
          stroke="#6936f4" 
          fillOpacity={1}
          fill="url(#colorRemoved)" 
        />
        <Line 
          type="monotone" 
          dataKey="added" 
          stroke="#4CAF50" 
          strokeWidth={3}
          dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
          activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
          name="Added " 
        />
        <Line 
          type="monotone" 
          dataKey="removed" 
          stroke="#6936f4" 
          strokeWidth={3}
          dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
          activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
          name="Taken Out " 
        />
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <div className="dashboard-container">
      <main>
        <h1 className="dashboard-title">Dashboard</h1>
        <h2 className="brand-welcome">Welcome to {brandName}</h2>
        
        <section className="inventory-summary">
          <h3>Inventory Summary</h3>
          <hr/>

          <div className="summary-grid">
            <div className="summary-item">
              <span className="summary-label">Total Items:</span>
              <span className="summary-value">{totalItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Low Stock Items:</span>
              <span className="summary-value">{lowStockItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Expired Items:</span>
              <span className="summary-value">{expiredItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Total Inventory Value:</span>
              <span className="summary-value">${totalValue.toFixed(2)}</span>
            </div>
          </div>
        </section>
        
        <section className="inventory-analytics">
          <div className="analytics-header">
            <h3>Inventory Analytics</h3>
            <p onClick={() => setIsExpanded(true)} className="more-analytics-btn">
               Analytics
            </p>
          
          </div>
          <hr/>
          <AnalyticsContent />
        </section>
      </main>
      
      {isExpanded && (
        <div className="expanded-analytics-overlay">
          <div className="expanded-analytics-content">
            <div className="expanded-header">
              <h3>Detailed Inventory Analytics</h3>
              <p onClick={() => setIsExpanded(false)} className="back-btn">
                Back to Dashboard
              </p>
            </div>
            <hr/>
            <div className="expanded-chart">
              <AnalyticsContent />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;