import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X, Search, ChevronRight } from 'lucide-react';
import './MobileMenuMarketResearch.css';

const MobileMenuMarketResearch = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isOpen]);

    const handleInventoryClick = () => {
        navigate('/inventory/dashboard');
        onClose();
    };

    return (
        <div className={`mobile-menu-overlay ${isOpen ? 'open' : ''}`}>
            <div ref={menuRef} className={`mobile-menu ${isOpen ? 'open' : ''}`}>
                <div className="mobile-menu-header">
                    <div className="mobile-menu-search">
                        <Search size={20} />
                        <input
                            className='mobile-search-input'
                            ref={searchInputRef}
                            type="text"
                            placeholder="Search Market Research..."
                        />
                    </div>
                    <button className="mobile-menu-close" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>
                <nav className="mobile-nav">
                    <ul>
                        <li>
                            <Link to="/search" onClick={onClose}>
                                Search
                                <ChevronRight size={20} />
                            </Link>
                        </li>
                        <li>
                            <Link to="/segmentation" onClick={onClose}>
                                Segmentation
                                <ChevronRight size={20} />
                            </Link>
                        </li>
                        <li>
                            <Link to="/analysis" onClick={onClose}>
                                Analysis
                                <ChevronRight size={20} />
                            </Link>
                        </li>
                        <li>
                            <Link to="/reports" onClick={onClose}>
                                Reports
                                <ChevronRight size={20} />
                            </Link>
                        </li>
                        <li>
                            <Link to="/tools" onClick={onClose}>
                                Tools
                                <ChevronRight size={20} />
                            </Link>
                        </li>
                        <li>
                            <button className="inventory-btn" onClick={handleInventoryClick}>
                                Go to Inventory
                                <ChevronRight size={20} />
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default MobileMenuMarketResearch;